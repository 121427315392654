<template>
	<div>
		<b-row>
			<b-col sm="12" :md="!forCarbonTemplate ? 3 : 4" :lg="!forCarbonTemplate ? 3 : 4" :xl="!forCarbonTemplate ? 3 : 4">
				<b-form-group :label="FormMSG(15, 'Energy detail')">
					<b-form-select
						v-model="dataToSend.type"
						:options="generatorEnergyOptions"
						:placeholder="FormMSG(52, 'Please select')"
						@change="handleChangeType"
					/>
				</b-form-group>
			</b-col>
			<b-col sm="12" :md="!forCarbonTemplate ? 3 : 4" :lg="!forCarbonTemplate ? 3 : 4" :xl="!forCarbonTemplate ? 3 : 4">
				<b-form-group :label="FormMSG(16, 'Generator type')">
					<b-form-select
						v-model="dataToSend.subType"
						:options="generatorTypeOptions"
						:placeholder="FormMSG(52, 'Please select')"
						@change="handleChangeSubType"
					/>
				</b-form-group>
			</b-col>
			<b-col
				v-if="[2, 3].includes(dataToSend.subType) && !forCarbonTemplate"
				sm="12"
				:md="!forCarbonTemplate ? 3 : 4"
				:lg="!forCarbonTemplate ? 3 : 4"
				:xl="!forCarbonTemplate ? 3 : 4"
			>
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(17, 'Quantity')">
					<b-input-group>
						<b-form-input v-model="dataToSend.liter" @change="handleChangeLiter" />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(104, 'L') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-90 mr-3">
						<b-form-group :label="FormMSG(17, 'Quantity')">
							<b-form-input v-model="dataToSend.liter" @change="handleChangeLiter" />
						</b-form-group>
					</div>
					<div class="d-flex align-items-center pt-3 text-capitalize">
						<div class="fs-20 fw-700">{{ FormMSG(104, 'L') }}</div>
					</div>
				</div>
			</b-col>
			<b-col
				v-if="dataToSend.subType === 4 && !forCarbonTemplate"
				sm="12"
				:md="!forCarbonTemplate ? 3 : 4"
				:lg="!forCarbonTemplate ? 3 : 4"
				:xl="!forCarbonTemplate ? 3 : 4"
			>
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(17, 'Quantity')">
					<b-input-group>
						<b-form-input v-model="dataToSend.mcube" @change="handleChangeMCube" />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(105, 'm3') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-90 mr-3">
						<b-form-group :label="FormMSG(17, 'Quantity')">
							<b-form-input v-model="dataToSend.mcube" @change="handleChangeMCube" />
						</b-form-group>
					</div>
					<div class="d-flex align-items-center pt-3 text-capitalize">
						<div class="fs-20 fw-700">{{ FormMSG(105, 'm3') }}</div>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import _ from 'lodash';

export default {
	name: 'CoTwoGeneratorEnergyForm',

	mixins: [languageMessages],

	props: {
		coTwoData: { type: Object, required: true },
		forCarbonTemplate: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			generatorTypeSelected: 0,

			dataToSend: {
				type: 0,
				subType: 0,
				liter: 0,
				mcube: 0
			}
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend.type = newVal.type;
					this.dataToSend.subType = newVal.subType;
					this.dataToSend.liter = newVal.liter;
					this.dataToSend.mcube = newVal.mcube;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		generatorEnergyOptions() {
			return this.FormMenu(11013);
		},
		generatorTypeOptions() {
			return this.FormMenu(11014);
		}
	},

	methods: {
		handleChangeMCube() {
			this.emitChange();
		},
		handleChangeLiter() {
			this.emitChange();
		},
		handleChangeSubType() {
			this.emitChange();
		},
		handleChangeType() {
			this.emitChange();
		},
		emitChange() {
			this.dataToSend.liter = parseFloat(this.dataToSend.liter);
			this.dataToSend.mcube = parseFloat(this.dataToSend.mcube);

			this.$emit('co-two-generator-energy:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
