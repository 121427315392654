var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: 4, lg: 4, xl: 4 } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: { label: _vm.FormMSG(118, "Type") },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.typeOptions,
                      placeholder: _vm.FormMSG(52, "Please select"),
                    },
                    on: { change: _vm.handleChangeType },
                    model: {
                      value: _vm.dataToSend.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "type", $$v)
                      },
                      expression: "dataToSend.type",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }