<template>
	<div>
		<b-row>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(15, 'Vegetarian/Vegan meal')">
					<b-form-input v-model="dataToSend.mealVege" @change="handleChange" />
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-125-px mr-3">
						<b-form-input v-model="dataToSend.mealVege" @change="handleChange" />
					</div>
					<div class="d-flex align-items-center text-lowercase">
						<div class="fs-14">{{ FormMSG(15, 'Vegetarian/Vegan meal') }}</div>
					</div>
				</div>
			</b-col>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(16, 'Red meat meals')">
					<b-form-input v-model="dataToSend.mealRed" @change="handleChange" />
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-125-px mr-3">
						<b-form-input v-model="dataToSend.mealRed" @change="handleChange" />
					</div>
					<div class="d-flex align-items-center text-lowercase">
						<div class="fs-14">{{ FormMSG(16, 'Red meat meals') }}</div>
					</div>
				</div>
			</b-col>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(17, 'Fish/white meat meals')">
					<b-form-input v-model="dataToSend.mealWhite" @change="handleChange" />
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-125-px mr-3">
						<b-form-input v-model="dataToSend.mealWhite" @change="handleChange" />
					</div>
					<div class="d-flex align-items-center text-lowercase">
						<div class="fs-14">{{ FormMSG(17, 'Fish/white meat meals') }}</div>
					</div>
				</div>
			</b-col>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(35, 'Not specified')">
					<b-form-input v-model="dataToSend.mealNotSpecified" @change="handleChange" />
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-125-px mr-3">
						<b-form-input v-model="dataToSend.mealNotSpecified" @change="handleChange" />
					</div>
					<div class="d-flex align-items-center text-lowercase">
						<div class="fs-14">{{ FormMSG(35, 'Not specified') }}</div>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import _ from 'lodash';

export default {
	name: 'CoTwoMealForm',

	mixins: [languageMessages],

	props: {
		coTwoData: { type: Object, required: true }
	},

	data() {
		return {
			dataToSend: {
				mealRed: 0,
				mealWhite: 0,
				mealVege: 0,
				mealNotSpecified: 0
			}
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend.mealRed = newVal.mealRed;
					this.dataToSend.mealWhite = newVal.mealWhite;
					this.dataToSend.mealVege = newVal.mealVege;
					this.dataToSend.mealNotSpecified = newVal.mealNotSpecified;
				}
			},
			immediate: true,
			deep: true
		}
	},

	methods: {
		handleChange() {
			this.emitChange();
		},

		emitChange() {
			this.dataToSend.mealRed = !_.isNil(this.dataToSend.mealRed) ? +this.dataToSend.mealRed : 0;
			this.dataToSend.mealWhite = !_.isNil(this.dataToSend.mealWhite) ? +this.dataToSend.mealWhite : 0;
			this.dataToSend.mealVege = !_.isNil(this.dataToSend.mealVege) ? +this.dataToSend.mealVege : 0;
			this.dataToSend.mealNotSpecified = !_.isNil(this.dataToSend.mealNotSpecified) ? +this.dataToSend.mealNotSpecified : 0;

			this.$emit('co-two-meal:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
