var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.hideCoTwoCategorySelector
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-1",
                      attrs: { label: _vm.FormMSG(14, "CO2 type") },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          options: _vm.coTwoTypeOptions,
                          placeholder: _vm.FormMSG(85, "Select CO2 type"),
                          disabled: _vm.disableCarbonTypeSelector,
                        },
                        on: { change: _vm.handleChangeCoTwoCategory },
                        model: {
                          value: _vm.dataToSend.coTwoCategory,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "coTwoCategory", $$v)
                          },
                          expression: "dataToSend.coTwoCategory",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.forCarbonTemplate
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "text-color-blue-streak d-flex align-items-center",
                        },
                        [
                          _c(_vm.getLucideIcon("Info"), {
                            tag: "component",
                            attrs: { size: 16 },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "ml-1",
                              staticStyle: { "margin-top": "3px" },
                            },
                            [_vm._v(_vm._s(_vm.infoText))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.dataToSend.coTwoCategory === 1 ||
              _vm.dataToSend.coTwoCategory === 15
                ? _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "pb-0 mb-0",
                              attrs: { for: "type" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center fs-12",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(631, "Energy saving plan")
                                      ) +
                                      " \n\t\t\t\t\t\t"
                                  ),
                                  _c(
                                    "info-tooltip",
                                    { staticStyle: { "margin-bottom": "2px" } },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              643,
                                              "Have you implemented the Ademe energy saving plan?  Information can be found here:"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            target: "blank",
                                            href: "https://agirpourlatransition.ademe.fr/entreprises/sobriete-energetique-entreprises",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(644, "Ademe wabsite")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("b-form-select", {
                            attrs: { options: _vm.energySavingOptions },
                            on: {
                              change: _vm.handleOptionSelectedEnergySaving,
                            },
                            model: {
                              value: _vm.energySavingSelected,
                              callback: function ($$v) {
                                _vm.energySavingSelected = $$v
                              },
                              expression: "energySavingSelected",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dataToSend.coTwoCategory === 4 &&
              _vm.$screen.width > 576 &&
              _vm.hideDistanceKnownTransport === false
                ? _c(
                    "b-col",
                    {
                      staticClass: "pt-4",
                      attrs: { sm: "6", md: "4", lg: "4", xl: "4" },
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { size: "lg", switch: "" },
                          on: { change: _vm.handleChangeDistanceKnown },
                          model: {
                            value: _vm.transportDistanceKnown,
                            callback: function ($$v) {
                              _vm.transportDistanceKnown = $$v
                            },
                            expression: "transportDistanceKnown",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex align-items-center fs-14",
                              staticStyle: { "margin-top": "2px" },
                            },
                            [_vm._v(_vm._s(_vm.FormMSG(247, "Distance known")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 1 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 1 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-location-form", {
                    attrs: { "co-two-data": _vm.carbonData },
                    on: {
                      "co-two-location:change": _vm.handleCoTwoLocationChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 2 } },
        [
          _vm.dataToSend.coTwoCategory === 2
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-accomodation-form", {
                    ref: "coTwoAccommodationFormRef",
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "hide-number-of-bedroom": _vm.forCarbonTemplate,
                      "hide-number-of-night": _vm.forCarbonTemplate,
                      "hide-total-number-of-night": _vm.forCarbonTemplate,
                      "disable-forms": _vm.disableAccomodationForms,
                    },
                    on: {
                      "co-two-accomodation:change":
                        _vm.handleCoTwoAccomodationChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 3 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 3 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-energy-form", {
                    ref: "coTwoEnergyFormRef",
                    attrs: { "co-two-data": _vm.carbonData },
                    on: { "co-two-energy:change": _vm.handleCoTwoEnergyChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 4 } },
        [
          _vm.dataToSend.coTwoCategory === 4
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-transport-form", {
                    ref: "coTwoTransportFormRef",
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "is-distance-known": _vm.transportDistanceKnown,
                      "hide-from-address":
                        _vm.forCarbonTemplate || _vm.hideFromAddressTransport,
                      "hide-to-address":
                        _vm.forCarbonTemplate || _vm.hideToAddressTransport,
                      "disable-forms": _vm.disableTransportForms,
                      "use-address-string": _vm.useAddressTransportString,
                      "hide-distance-known": _vm.hideDistanceKnownTransport,
                      "hide-transport-type-selector":
                        _vm.hideTransportTypeSelectorTransport,
                      "hide-distance": _vm.hideDistanceTransport,
                      "get-my-vehicules": _vm.getMyVehiculeInTransport,
                    },
                    on: {
                      "co-two-transport:change": _vm.handleCoTwoTransportChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 6 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 6 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-meal-form", {
                    attrs: { "co-two-data": _vm.carbonData },
                    on: { "co-two-meal:change": _vm.handleCoTwoMealChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 7 } },
        [
          _vm.dataToSend.coTwoCategory === 7
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-hmc-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "for-carbon-template": _vm.forCarbonTemplate,
                    },
                    on: { "co-two-hmc:change": _vm.handleCoTwoHmcChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 8 } },
        [
          _vm.dataToSend.coTwoCategory === 8
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-set-creation-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "for-carbon-template": _vm.forCarbonTemplate,
                    },
                    on: {
                      "co-two-set-creation:change":
                        _vm.handleCoTwoSetCreationChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 9 } },
        [
          _vm.dataToSend.coTwoCategory === 9
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-tehnical-production-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "for-carbon-template": _vm.forCarbonTemplate,
                    },
                    on: {
                      "co-two-tehnical-production:change":
                        _vm.handleCoTwoTechnicalProductionChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 10 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 10 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-transportation-shooting-form", {
                    attrs: { "co-two-data": _vm.carbonData },
                    on: {
                      "co-two-transportation-shooting:change":
                        _vm.handleCoTwoTransportationShootingChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 5 } },
        [
          _vm.dataToSend.coTwoCategory === 5
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-waste-form", {
                    ref: "coTwoWasteFormRef",
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "hide-fields": _vm.forCarbonTemplate,
                    },
                    on: { "co-two-waste:change": _vm.handleCoTwoWasteChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 11 } },
        [
          _vm.dataToSend.coTwoCategory === 11
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-generator-energy-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "for-carbon-template": _vm.forCarbonTemplate,
                    },
                    on: {
                      "co-two-generator-energy:change":
                        _vm.handleCoTwoGeneratorEnergyChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 12 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 12 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-transport-car-lodges-form", {
                    ref: "coTwoTransportCarLodgesRef",
                    attrs: { "co-two-data": _vm.carbonData },
                    on: {
                      "co-two-transport-car-lodges:change":
                        _vm.handleCoTwoTransportCarLodgesChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 13 } },
        [
          _vm.dataToSend.coTwoCategory === 13
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-fuel-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "hide-quantity": _vm.forCarbonTemplate,
                    },
                    on: { "co-two-fuel:change": _vm.handleCoTwoFuelChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 14 } },
        [
          _vm.dataToSend.coTwoCategory === 14
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-production-logistic", {
                    attrs: { "co-two-data": _vm.carbonData },
                    on: {
                      "co-two-production-logistic:change":
                        _vm.handleCoTwoProductionLogistic,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 15 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 15 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-premise-form", {
                    attrs: { "co-two-data": _vm.carbonData },
                    on: {
                      "co-two-premise:change": _vm.handleCoTwoPremiseChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.hideBtnReviewed === false
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    [
                      _vm.parentId > 0 &&
                      [0, 1, 2].includes(_vm.dataToSend.verified)
                        ? _c(
                            "b-col",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                sm: "12",
                                md: "5",
                                "offset-md": "7",
                                lg: "5",
                                "offset-lg": "7",
                                xl: "5",
                                "offset-xl": "7",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "blue-sapphire",
                                    block: "",
                                    disabled: _vm.loadingForMarkReview,
                                  },
                                  on: { click: _vm.handleClickReviewed },
                                },
                                [
                                  _vm.loadingForMarkReview === false
                                    ? _c(_vm.getLucideIcon("CheckCircle"), {
                                        tag: "component",
                                        attrs: { size: 16 },
                                      })
                                    : _vm._e(),
                                  _vm.loadingForMarkReview === true
                                    ? _c("b-spinner", { attrs: { small: "" } })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          781,
                                          "Mark as reviewed and go to next"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.parentId > 0 && _vm.dataToSend.verified === 3
                        ? _c(
                            "b-col",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                sm: "12",
                                md: "5",
                                "offset-md": "7",
                                lg: "5",
                                "offset-lg": "7",
                                xl: "5",
                                "offset-xl": "7",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-primary fs-18 float-right",
                                },
                                [
                                  _c(_vm.getLucideIcon("Check"), {
                                    tag: "component",
                                    attrs: { size: 20 },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.FormMSG(812, "Already reviewed")
                                      ) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }