<template>
	<div>
		<b-row>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(15, 'Vehicle type')">
					<b-form-select
						v-model="dataToSend.type"
						:options="transportationShootingOptions"
						:placeholder="FormMSG(16, 'Please select ...')"
						@change="handleChangeType"
					/>
				</b-form-group>
			</b-col>
			<b-col v-if="[1, 2, 3, 5].includes(dataToSend.type)" sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(17, 'Distance')">
					<b-input-group>
						<b-form-input v-model="dataToSend.km" @change="handleChangeKm" />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ distanceUnit }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col v-if="[4, 6, 7].includes(dataToSend.type)" sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(18, 'Flight duration')">
					<input-date-time-picker
						:label="FormMSG(18, 'Flight duration')"
						:uiid="'flightDuration'"
						:type="'text'"
						:placeholder="'hh:mm'"
						:item="dataToSend.number"
						:error="null"
						:nbHour="rangeHour[WORKTYPE.daily]"
						:nbMinute="rangeMinute[WORKTYPE.daily]"
						:fieldName="'number'"
						:isEditable="() => true"
						:workType="WORKTYPE.daily"
						@change="handleChangeFlightDuration"
					/>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import globalMixn from '@/mixins/global.mixin';
import _ from 'lodash';
import { useWorktype, useRangeHour, useRangeMinute } from '@/utils/utils';
import InputDateTimePicker from '@/components/InputDateTimePicker/InputDateTimePicker';

export default {
	name: 'CoTwoTransportationShootingForm',

	mixins: [languageMessages, globalMixn],

	props: {
		coTwoData: { type: Object, required: true }
	},

	components: {
		InputDateTimePicker
	},

	data() {
		return {
			transportationShootingSelected: 0,

			WORKTYPE: useWorktype,
			rangeHour: useRangeHour,
			rangeMinute: useRangeMinute,

			dataToSend: {
				type: 1,
				km: 0,
				number: '00:00'
			}
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend.type = newVal.type;
					this.dataToSend.km = newVal.km;
					this.$nextTick(() => {
						this.dataToSend.number = this.convertMinutesToHours(newVal.number);
					});
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		transportationShootingOptions() {
			return this.FormMenu(11011);
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit.toLowerCase();
		}
	},

	methods: {
		convertMinutesToHours(value) {
			if (value <= 0) {
				return '00:00';
			} else {
				let min = value;
				let hours = min / 60;
				let rhours = Math.floor(hours);
				let minutes = (hours - rhours) * 60;
				let rminutes = Math.round(minutes);

				let result = `${rhours < 10 ? '0' + rhours : rhours}:${rminutes < 10 ? '0' + rminutes : rminutes}`;

				return result === '00:00' ? '00:00' : result;
			}
		},
		convertHoursToMinutes(value) {
			if (value === '--:--') return 0;
			let valueSplited = value.split(':');
			let minutes = parseInt(valueSplited[0], 10) * 60 + parseInt(valueSplited[1], 10);

			return minutes;
		},
		handleChangeFlightDuration({ hourValid }) {
			this.dataToSend.number = hourValid;
			this.emitChange();
		},
		handleChangeKm() {
			this.emitChange();
		},
		handleChangeType() {
			this.emitChange();
		},
		emitChange() {
			this.dataToSend.km = parseFloat(this.dataToSend.km);
			// this.dataToSend.number = this.dataToSend.number === '--:--' ? 0 : this.dataToSend.number;
			this.dataToSend.number = this.convertHoursToMinutes(this.dataToSend.number);
			this.$emit('co-two-transportation-shooting:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
