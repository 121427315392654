var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              attrs: {
                sm: "12",
                md: !_vm.forCarbonTemplate ? 3 : 4,
                lg: !_vm.forCarbonTemplate ? 3 : 4,
                xl: !_vm.forCarbonTemplate ? 3 : 4,
              },
            },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(15, "Energy detail") } },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.generatorEnergyOptions,
                      placeholder: _vm.FormMSG(52, "Please select"),
                    },
                    on: { change: _vm.handleChangeType },
                    model: {
                      value: _vm.dataToSend.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "type", $$v)
                      },
                      expression: "dataToSend.type",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            {
              attrs: {
                sm: "12",
                md: !_vm.forCarbonTemplate ? 3 : 4,
                lg: !_vm.forCarbonTemplate ? 3 : 4,
                xl: !_vm.forCarbonTemplate ? 3 : 4,
              },
            },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(16, "Generator type") } },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.generatorTypeOptions,
                      placeholder: _vm.FormMSG(52, "Please select"),
                    },
                    on: { change: _vm.handleChangeSubType },
                    model: {
                      value: _vm.dataToSend.subType,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "subType", $$v)
                      },
                      expression: "dataToSend.subType",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          [2, 3].includes(_vm.dataToSend.subType) && !_vm.forCarbonTemplate
            ? _c(
                "b-col",
                {
                  attrs: {
                    sm: "12",
                    md: !_vm.forCarbonTemplate ? 3 : 4,
                    lg: !_vm.forCarbonTemplate ? 3 : 4,
                    xl: !_vm.forCarbonTemplate ? 3 : 4,
                  },
                },
                [
                  _vm.$screen.width > 576
                    ? _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(17, "Quantity") } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                on: { change: _vm.handleChangeLiter },
                                model: {
                                  value: _vm.dataToSend.liter,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataToSend, "liter", $$v)
                                  },
                                  expression: "dataToSend.liter",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "bg-color-north-wind text-color-white",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(104, "L")) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "d-flex mt-3" }, [
                        _c(
                          "div",
                          { staticClass: "w-90 mr-3" },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: _vm.FormMSG(17, "Quantity") } },
                              [
                                _c("b-form-input", {
                                  on: { change: _vm.handleChangeLiter },
                                  model: {
                                    value: _vm.dataToSend.liter,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataToSend, "liter", $$v)
                                    },
                                    expression: "dataToSend.liter",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center pt-3 text-capitalize",
                          },
                          [
                            _c("div", { staticClass: "fs-20 fw-700" }, [
                              _vm._v(_vm._s(_vm.FormMSG(104, "L"))),
                            ]),
                          ]
                        ),
                      ]),
                ],
                1
              )
            : _vm._e(),
          _vm.dataToSend.subType === 4 && !_vm.forCarbonTemplate
            ? _c(
                "b-col",
                {
                  attrs: {
                    sm: "12",
                    md: !_vm.forCarbonTemplate ? 3 : 4,
                    lg: !_vm.forCarbonTemplate ? 3 : 4,
                    xl: !_vm.forCarbonTemplate ? 3 : 4,
                  },
                },
                [
                  _vm.$screen.width > 576
                    ? _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(17, "Quantity") } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                on: { change: _vm.handleChangeMCube },
                                model: {
                                  value: _vm.dataToSend.mcube,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataToSend, "mcube", $$v)
                                  },
                                  expression: "dataToSend.mcube",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "bg-color-north-wind text-color-white",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(105, "m3")) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "d-flex mt-3" }, [
                        _c(
                          "div",
                          { staticClass: "w-90 mr-3" },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: _vm.FormMSG(17, "Quantity") } },
                              [
                                _c("b-form-input", {
                                  on: { change: _vm.handleChangeMCube },
                                  model: {
                                    value: _vm.dataToSend.mcube,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataToSend, "mcube", $$v)
                                    },
                                    expression: "dataToSend.mcube",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center pt-3 text-capitalize",
                          },
                          [
                            _c("div", { staticClass: "fs-20 fw-700" }, [
                              _vm._v(_vm._s(_vm.FormMSG(105, "m3"))),
                            ]),
                          ]
                        ),
                      ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }