<template>
	<div>
		<div>
			<include-elect :parent-id="locationId" :for-create="forCreate" :edit-data="elecData" @change="handleChangeElect" />
		</div>
		<div>
			<include-gas :parent-id="locationId" :for-create="forCreate" :edit-data="gasData" @change="handleChangeGas" />
		</div>
		<div>
			<include-water :parent-id="locationId" :for-create="forCreate" :edit-data="waterData" @change="handleChangeWater" />
		</div>
		<div>
			<generator :parent-id="locationId" :for-create="forCreate" :edit-data="generatorData" @change="handleChangeGenerator" />
		</div>
		<b-row>
			<b-col sm="12" md="2" lg="2" xl="2">
				<b-form-group>
					<template #label> &nbsp; </template>
					<b-form-input :value="FormMSG(564, 'Paper printed')" class="bg-color-grams-hair" disabled />
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(578, 'Number')">
					<b-form-input v-model="paperPrinted" @change="handleChangePaperPrinted" />
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import IncludeElect from '@/components/LocationService/_Elect';
import IncludeGas from '@/components/LocationService/_Gas';
import IncludeWater from '@/components/LocationService/_Water';
import Generator from '@/components/LocationService/Generator';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'CoTwoPremiseForm',

	components: {
		IncludeElect,
		IncludeGas,
		IncludeWater,
		Generator
	},

	props: {
		coTwoData: { type: Object, default: () => ({}), required: false }
	},

	mixins: [languageMessages],

	data() {
		return {
			locationId: 0,
			elecData: {
				elecMeterEnd: 0,
				elecMeterEndPict: '',
				elecMeterStart: 0,
				elecMeterStartPict: '',
				elecSupplier: 0,
				consumptionElectricity: 0
			},
			gasData: {
				gasMeterEnd: 0,
				gasMeterEndPict: '',
				gasMeterStart: 0,
				gasMeterStartPict: '',
				gasSupplier: 0,
				consumptionGas: 0
			},
			waterData: {
				waterMeterEnd: 0,
				waterMeterEndPict: '',
				waterMeterStart: 0,
				waterMeterStartPict: '',
				waterSupplier: 0,
				consumptionWater: 0
			},
			generatorData: {
				generatorVolumeUsed: 0,
				generatorFuelType: 0,
				generatorPict: '',
				paidByProduction: false
			},
			greenEnergySupplier: false,
			recycledPaper: false,
			sortedWaste: true,
			paperPrinted: 0,
			forCreate: true
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				this.elecData.elecMeterEnd = newVal.elecMeterEnd;
				this.elecData.elecMeterEndPict = '';
				this.elecData.elecMeterStart = newVal.elecMeterStart;
				this.elecData.elecMeterStartPict = '';
				this.elecData.elecSupplier = newVal.elecSupplier;
				this.elecData.consumptionElectricity = newVal.consumptionElectricity;

				this.gasData.gasMeterEnd = newVal.gasMeterEnd;
				this.gasData.gasMeterEndPict = '';
				this.gasData.gasMeterStart = newVal.gasMeterStart;
				this.gasData.gasMeterStartPict = '';
				this.gasData.gasSupplier = newVal.gasSupplier;
				this.gasData.consumptionGas = newVal.consumptionGas;

				this.waterData.waterMeterEnd = newVal.waterMeterEnd;
				this.waterData.waterMeterEndPict = '';
				this.waterData.waterMeterStart = newVal.waterMeterStart;
				this.waterData.waterMeterStartPict = '';
				this.waterData.waterSupplier = newVal.waterSupplier;
				this.waterData.consumptionWater = newVal.consumptionWater;

				this.generatorData.generatorVolumeUsed = newVal.generatorVolumeUsed;
				this.generatorData.generatorFuelType = newVal.generatorFuelType;
				this.generatorData.generatorPict = '';
				this.generatorData.paidByProduction = newVal.paidByProduction;

				this.sortedWaste = newVal.sortedWaste;
				this.paperPrinted = newVal.paperPrinted;
				this.recycledPaper = newVal.recycledPaper;
				this.greenEnergySupplier = newVal.greenEnergySupplier;
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		yesNoOptions() {
			return [
				{ text: this.FormMSG(15, 'No'), value: false },
				{ text: this.FormMSG(14, 'Yes'), value: true }
			];
		}
	},

	methods: {
		handleChangePaperPrinted() {
			this.emitChange();
		},
		handleChangeRadios() {
			this.emitChange();
		},
		handleChangeGenerator(payload) {
			this.generatorData.generatorVolumeUsed = payload.generatorVolumeUsed;
			this.generatorData.generatorFuelType = payload.generatorFuelType;
			this.generatorData.generatorPict = payload.generatorPict;
			this.generatorData.paidByProduction = payload.paidByProduction;

			this.emitChange();
		},
		handleChangeWater(payload) {
			this.waterData.waterMeterEnd = payload.waterMeterEnd;
			this.waterData.waterMeterEndPict = payload.waterMeterEndPict;
			this.waterData.waterMeterStart = payload.waterMeterStart;
			this.waterData.waterMeterStartPict = payload.waterMeterStartPict;
			this.waterData.waterSupplier = payload.waterSupplier;
			this.waterData.consumptionWater = payload.consumptionWater;

			this.emitChange();
		},
		handleChangeGas(payload) {
			this.gasData.gasMeterEnd = payload.gasMeterEnd;
			this.gasData.gasMeterEndPict = payload.gasMeterEndPict;
			this.gasData.gasMeterStart = payload.gasMeterStart;
			this.gasData.gasMeterStartPict = payload.gasMeterStartPict;
			this.gasData.gasSupplier = payload.gasSupplier;
			this.gasData.consumptionGas = payload.consumptionGas;

			this.emitChange();
		},
		handleChangeElect(payload) {
			this.elecData.elecMeterEnd = payload.elecMeterEnd;
			this.elecData.elecMeterEndPict = payload.elecMeterEndPict;
			this.elecData.elecMeterStart = payload.elecMeterStart;
			this.elecData.elecMeterStartPict = payload.elecMeterStartPict;
			this.elecData.elecSupplier = payload.elecSupplier;
			this.elecData.consumptionElectricity = payload.consumptionElectricity;

			this.emitChange();
		},
		emitChange() {
			this.$emit('co-two-premise:change', {
				elecData: this.elecData,
				gasData: this.gasData,
				waterData: this.waterData,
				generatorData: this.generatorData,
				greenEnergySupplier: this.greenEnergySupplier,
				recycledPaper: this.recycledPaper,
				sortedWaste: this.sortedWaste,
				paperPrinted: +this.paperPrinted
			});
		}
	}
};
</script>
