<template>
	<div>
		<b-row>
			<b-col sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(118, 'Type')" class="mb-0">
					<b-form-select v-model="dataToSend.type" :options="typeOptions" :placeholder="FormMSG(52, 'Please select')" @change="handleChangeType" />
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import _ from 'lodash';
import { CARBON_PRODUCTION_LOGISTIC_TYPE } from '@/shared/constants';

export default {
	name: 'CoTwoProductionLogistic',

	mixins: [languageMessages],

	props: {
		coTwoData: { type: Object, required: true },
		forCarbonTemplate: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			dataToSend: {
				type: 0
			}
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend.type = newVal.type;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		typeOptions() {
			return this.FormMenu(CARBON_PRODUCTION_LOGISTIC_TYPE);
		}
	},

	methods: {
		handleChangeType() {
			this.emitChange();
		},
		emitChange() {
			this.$emit('co-two-production-logistic:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
