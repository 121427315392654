var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-collapse",
        { attrs: { visible: !_vm.canCompute } },
        [
          _vm.canCompute === false
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12" } },
                    [
                      _c(
                        "b-alert",
                        { attrs: { show: "", variant: "warning" } },
                        [
                          _c(_vm.getLucideIcon("AlertTriangle"), {
                            tag: "component",
                            attrs: { size: 16 },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.FormMSG(
                                  89,
                                  "This transport mean type is not computed by Google"
                                )
                              ) +
                              "\n\t\t\t\t"
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: 4, lg: 4, xl: 4 } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(15, "Reason for transport") } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.reasonTransportOptions },
                    on: { change: _vm.handleInputReason },
                    model: {
                      value: _vm.dataToSend.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "reason", $$v)
                      },
                      expression: "dataToSend.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: 4, lg: 4, xl: 4 } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(45, "Vehicle") } },
                [
                  _c("v-select", {
                    attrs: {
                      label: "name",
                      options: _vm.vehicules,
                      placeholder: _vm.FormMSG(85, "Please select"),
                      reduce: (option) => +option.id,
                      clearable: false,
                      disabled: _vm.disableForms,
                    },
                    on: { input: _vm.handleInputVehiculeId },
                    model: {
                      value: _vm.dataToSend.vehiculeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "vehiculeId", $$v)
                      },
                      expression: "dataToSend.vehiculeId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: 4, lg: 4, xl: 4 } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(17, "Number of vehicle") } },
                [
                  _c("b-form-input", {
                    class: { "bg-color-grams-hair": _vm.disableForms },
                    attrs: { disabled: _vm.disableForms },
                    on: { change: _vm.handleChangeNumberVehicle },
                    model: {
                      value: _vm.dataToSend.numberOfVehicule,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "numberOfVehicule", $$v)
                      },
                      expression: "dataToSend.numberOfVehicule",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.hideTransportTypeSelector === false
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: 12, lg: 12, xl: 12 } },
                [
                  _c("transport-type-selector", {
                    ref: "transportTypSelectorCoTwo",
                    attrs: {
                      "data-additional-detail":
                        _vm.dataToSend.additionalSubType,
                      "data-detail": _vm.dataToSend.subType,
                      "data-type": _vm.dataToSend.type,
                      "disable-form": _vm.disableForms,
                    },
                    on: {
                      "transportation-type-selector:change":
                        _vm.handleTransportationTypeSelectorChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$screen.width <= 576 && _vm.hideDistanceKnown === false
        ? _c(
            "b-row",
            { staticClass: "mt-2 mb-3" },
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        size: "lg",
                        disabled: _vm.disableForms,
                        switch: "",
                      },
                      model: {
                        value: _vm.dataToSend.distanceKnown,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataToSend, "distanceKnown", $$v)
                        },
                        expression: "dataToSend.distanceKnown",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center fs-14" },
                        [_vm._v(_vm._s(_vm.FormMSG(247, "Distance known")))]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _vm.dataToSend.distanceKnown === false && !_vm.hideFromAddress
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _vm.useAddressString === false
                    ? _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(18, "From") } },
                        [
                          _c("v-address", {
                            attrs: {
                              params: {
                                title: _vm.FormMSG(
                                  48,
                                  "From address transport"
                                ),
                              },
                              "address-id": _vm.dataToSend.fromAddressId,
                              disabled: _vm.disableForms,
                            },
                            on: {
                              change: _vm.handleChangeFromAddress,
                              delete: _vm.handleDeleteFromAddress,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(18, "From") } },
                        [
                          _c("b-form-input", {
                            staticClass: "bg-color-grams-hair",
                            attrs: {
                              value: _vm.coTwoData.fromAddress,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          _vm.dataToSend.distanceKnown === false && !_vm.hideToAddress
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _vm.useAddressString === false
                    ? _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(19, "To") } },
                        [
                          _c("v-address", {
                            attrs: {
                              params: {
                                title: _vm.FormMSG(58, "To address transport"),
                              },
                              "address-id": _vm.dataToSend.toAddressId,
                              disabled: _vm.disableForms,
                            },
                            on: {
                              change: _vm.handleChangeToAddress,
                              delete: _vm.handleDeleteToAddress,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(19, "To") } },
                        [
                          _c("b-form-input", {
                            staticClass: "bg-color-grams-hair",
                            attrs: {
                              value: _vm.coTwoData.toAddress,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          _vm.hideDistance === false
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: 4, lg: 4, xl: 4 } },
                [
                  _vm.$screen.width > 576
                    ? _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(20, "Distance") } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                class: {
                                  "bg-color-grams-hair":
                                    !_vm.dataToSend.distanceKnown,
                                },
                                attrs: {
                                  disabled:
                                    !_vm.dataToSend.distanceKnown ||
                                    _vm.disableForms,
                                },
                                on: { change: _vm.handleChangeDistance },
                                model: {
                                  value: _vm.dataToSend.km,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataToSend, "km", $$v)
                                  },
                                  expression: "dataToSend.km",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "bg-color-north-wind text-color-white",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.distanceUnit) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "d-flex mt-3" }, [
                        _c(
                          "div",
                          { staticClass: "w-90 mr-3" },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: _vm.FormMSG(20, "Distance") } },
                              [
                                _c("b-form-input", {
                                  class: {
                                    "bg-color-grams-hair":
                                      !_vm.dataToSend.distanceKnown,
                                  },
                                  attrs: {
                                    disabled:
                                      !_vm.dataToSend.distanceKnown ||
                                      _vm.disableForms,
                                  },
                                  on: { change: _vm.handleChangeDistance },
                                  model: {
                                    value: _vm.dataToSend.km,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataToSend, "km", $$v)
                                    },
                                    expression: "dataToSend.km",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center pt-3 text-capitalize",
                          },
                          [
                            _c("div", { staticClass: "fs-20 fw-700" }, [
                              _vm._v(_vm._s(_vm.distanceUnit)),
                            ]),
                          ]
                        ),
                      ]),
                ],
                1
              )
            : _vm._e(),
          [9, 10, 11, 12, 19].includes(_vm.transportTypeMapped)
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _vm.$screen.width > 576
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(
                              21,
                              "Material weight (if Freight)"
                            ),
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: { disabled: _vm.disableForms },
                                on: { change: _vm.handleChangeMaterialWeight },
                                model: {
                                  value: _vm.dataToSend.number,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataToSend, "number", $$v)
                                  },
                                  expression: "dataToSend.number",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "bg-color-north-wind text-color-white",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(29, "kg")) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "d-flex mt-3" }, [
                        _c(
                          "div",
                          { staticClass: "w-90 mr-3" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.FormMSG(
                                    21,
                                    "Material weight (if Freight)"
                                  ),
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { disabled: _vm.disableForms },
                                  on: {
                                    change: _vm.handleChangeMaterialWeight,
                                  },
                                  model: {
                                    value: _vm.dataToSend.number,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataToSend, "number", $$v)
                                    },
                                    expression: "dataToSend.number",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center pt-3 text-capitalize",
                          },
                          [
                            _c("div", { staticClass: "fs-20 fw-700" }, [
                              _vm._v(_vm._s(_vm.FormMSG(29, "kg"))),
                            ]),
                          ]
                        ),
                      ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }