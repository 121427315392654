var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(261, "Reason"),
                    state: !_vm.$v.dataToSend.type.$error,
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.reasonTypes,
                      state: !_vm.$v.dataToSend.type.$error,
                    },
                    on: { change: _vm.handleChangeReason },
                    model: {
                      value: _vm.$v.dataToSend.type.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.dataToSend.type, "$model", $$v)
                      },
                      expression: "$v.dataToSend.type.$model",
                    },
                  }),
                  _vm.$v.dataToSend.type.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(296, "Reason is required")) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.dataToSend.type === 1 || _vm.dataToSend.type === 2
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.labelLocation } },
                    [
                      _c("v-select", {
                        class: {
                          "is-invalid": _vm.$v.dataToSend.locationId.$error,
                        },
                        attrs: {
                          placeholder: _vm.FormMSG(263, "Please select ..."),
                          label: "setName",
                          options: _vm.locations,
                          reduce: (option) => option.id,
                        },
                        on: {
                          "option:selected": _vm.handleOptionSelectedLocation,
                        },
                        model: {
                          value: _vm.$v.dataToSend.locationId.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.dataToSend.locationId,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.dataToSend.locationId.$model",
                        },
                      }),
                      _vm.$v.dataToSend.locationId.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(295, "Location is required")
                                ) +
                                "\n\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.dataToSend.type > 0
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(267, "Energy type"),
                        state: !_vm.$v.dataToSend.subType.$error,
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          options: _vm.energyTypes,
                          state: !_vm.$v.dataToSend.subType.$error,
                        },
                        on: { change: _vm.handleChangeSubType },
                        model: {
                          value: _vm.$v.dataToSend.subType.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.dataToSend.subType, "$model", $$v)
                          },
                          expression: "$v.dataToSend.subType.$model",
                        },
                      }),
                      _vm.$v.dataToSend.subType.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(300, "Energy type is required")
                                ) +
                                "\n\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(275, "Consumption"),
                        state: !_vm.$v.consumption.$error,
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: { state: !_vm.$v.consumption.$error },
                            on: { change: _vm.handleChangeConsumption },
                            model: {
                              value: _vm.$v.consumption.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.consumption, "$model", $$v)
                              },
                              expression: "$v.consumption.$model",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                {
                                  staticClass:
                                    "bg-color-north-wind text-color-white",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.unitConsumption) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.$v.consumption.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(300, "Energy type is required")
                                ) +
                                "\n\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }