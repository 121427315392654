var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("include-elect", {
            attrs: {
              "parent-id": _vm.locationId,
              "for-create": _vm.forCreate,
              "edit-data": _vm.elecData,
            },
            on: { change: _vm.handleChangeElect },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("include-gas", {
            attrs: {
              "parent-id": _vm.locationId,
              "for-create": _vm.forCreate,
              "edit-data": _vm.gasData,
            },
            on: { change: _vm.handleChangeGas },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("include-water", {
            attrs: {
              "parent-id": _vm.locationId,
              "for-create": _vm.forCreate,
              "edit-data": _vm.waterData,
            },
            on: { change: _vm.handleChangeWater },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("generator", {
            attrs: {
              "parent-id": _vm.locationId,
              "for-create": _vm.forCreate,
              "edit-data": _vm.generatorData,
            },
            on: { change: _vm.handleChangeGenerator },
          }),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
            [
              _c(
                "b-form-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [_vm._v("   ")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("b-form-input", {
                    staticClass: "bg-color-grams-hair",
                    attrs: {
                      value: _vm.FormMSG(564, "Paper printed"),
                      disabled: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(578, "Number") } },
                [
                  _c("b-form-input", {
                    on: { change: _vm.handleChangePaperPrinted },
                    model: {
                      value: _vm.paperPrinted,
                      callback: function ($$v) {
                        _vm.paperPrinted = $$v
                      },
                      expression: "paperPrinted",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }