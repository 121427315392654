<template>
	<div>
		<b-row>
			<b-col sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(15, 'Reason')" class="mb-0">
					<b-form-select
						v-model="dataToSend.type"
						:options="reasonSetCreationOptions"
						:placeholder="FormMSG(52, 'Please select')"
						@change="handleChangeType"
					/>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="5" lg="5" xl="5" class="pt-4">
				<b-form-checkbox switch size="lg" :value="true" :uncheched-value="false">
					<div class="fs-14" style="margin-top: 2px">{{ FormMSG(16, 'Eco-designed set') }}</div>
				</b-form-checkbox>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import _ from 'lodash';

export default {
	name: 'CoTwoSetCreationForm',

	mixins: [languageMessages],

	props: {
		coTwoData: { type: Object, required: true },
		forCarbonTemplate: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			dataToSend: {
				type: 0
			}
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) && !_.isNil(newVal)) {
					this.dataToSend.type = newVal.type;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		reasonSetCreationOptions() {
			return this.FormMenu(11009);
		}
	},

	methods: {
		handleChangeType(payload) {
			this.emitChange();
		},
		emitChange() {
			this.$emit('co-two-set-creation:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
