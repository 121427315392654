var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: 4, lg: 4, xl: 4 } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: { label: _vm.FormMSG(18, "Reason") },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.hmcOptions,
                      placeholder: _vm.FormMSG(52, "Please select"),
                    },
                    on: { change: _vm.handleChangeType },
                    model: {
                      value: _vm.dataToSend.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "type", $$v)
                      },
                      expression: "dataToSend.type",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          [1, 2].includes(_vm.dataToSend.type)
            ? _c(
                "b-col",
                {
                  staticClass: "pt-4",
                  attrs: { sm: "12", md: "5", lg: "5", xl: "5" },
                },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        switch: "",
                        size: "lg",
                        value: true,
                        "unchecked-value": false,
                      },
                      on: { change: _vm.emitChange },
                      model: {
                        value: _vm.dataToSend.boolValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataToSend, "boolValue", $$v)
                        },
                        expression: "dataToSend.boolValue",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center fs-14" },
                        [_vm._v(_vm._s(_vm.FormMSG(19, "Re-use valuation")))]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }