<template>
	<div>
		<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside bg-color-white border-ligth-starlight' : ''}`">
			<legend v-if="$screen.width <= 576" :class="`${$screen.width <= 576 ? 'card-inside' : ''}`" class="text-color-rhapsody-in-blue-2">
				{{ FormMSG(687, 'Gas') }}
			</legend>
			<div :class="{ 'pt-1 pb-1': $screen.width <= 576 }">
				<b-row>
					<b-col v-if="$screen.width > 576" sm="12" md="2" lg="2" xl="2">
						<b-form-input :value="FormMSG(687, 'Gas')" class="bg-color-grams-hair" disabled />
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<supplier-energy-consumption
							:label="FormMSG(633, 'Supplier')"
							:dialog-title="(253, 'Gas supplier')"
							:hide-label="$screen.width > 576"
							supplier-type="gas"
							:label-add-supplier="FormMSG(198, 'New gas supplier')"
							:supplier-id="payload.gasSupplier"
							@supplier-energy:change="handleSupplierSelectorChange"
							@supplier-energy:unselect="handleSupplierUnselect"
						/>
					</b-col>
					<b-col sm="12" md="2" lg="2" xl="2">
						<b-form-group :label="$screen.width <= 576 ? FormMSG(58, 'Meter start') : ''">
							<b-input-group>
								<b-form-input id="location_gas_start" v-model="payload.gasMeterStart" placeholder="0" class="br-none" />
								<b-input-group-append>
									<b-input-group-text class="bg-color-white input-group-text-bg-white">
										<div class="d-flex">
											<button
												v-if="has(payload.gasMeterStartPict) && payload.gasMeterStartPict !== ''"
												class="btn-transparent text-color-blue-rinse"
												@click="showPicture([payload.gasMeterStartPict])"
											>
												<component :is="getLucideIcon('Image')" :size="20" />
											</button>
											<button v-if="!$isPwa()" class="btn-transparent text-color-rhapsody-in-blue" @click="openGasPicModalStart">
												<component :is="getLucideIcon('Paperclip')" :size="20" />
											</button>
											<button
												v-if="$isPwa()"
												class="btn-transparent text-color-rhapsody-in-blue"
												@click="startMobilePwaCapture('handleGasPicStart')"
											>
												<component :is="getLucideIcon('Paperclip')" :size="20" />
											</button>
										</div>
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="2" lg="2" xl="2">
						<b-form-group :label="$screen.width <= 576 ? FormMSG(59, 'Meter end') : ''">
							<b-input-group>
								<b-form-input v-model="payload.gasMeterEnd" placeholder="0" class="br-none" />
								<b-input-group-append>
									<b-input-group-text class="bg-color-white input-group-text-bg-white">
										<div class="d-flex">
											<button
												v-if="has(payload.gasMeterEndPict) && payload.gasMeterEndPict !== ''"
												class="btn-transparent text-color-blue-rinse"
												@click="showPicture([payload.gasMeterEndPict])"
											>
												<component :is="getLucideIcon('Image')" :size="20" />
											</button>
											<button v-if="!$isPwa()" class="btn-transparent text-color-rhapsody-in-blue" @click="openGasPicModalEnd">
												<component :is="getLucideIcon('Paperclip')" :size="20" />
											</button>
											<button
												v-if="$isPwa()"
												class="btn-transparent text-color-rhapsody-in-blue"
												@click="startMobilePwaCapture('handleGasPicEnd')"
											>
												<component :is="getLucideIcon('Paperclip')" :size="20" />
											</button>
										</div>
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="2" lg="2" xl="2">
						<b-form-group>
							<b-input-group>
								<b-form-input v-model="payload.consumptionGas" />
								<b-input-group-append>
									<b-input-group-text class="bg-color-north-wind text-color-white">
										{{ FormMSG(260, 'm3') }}
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
		</fieldset>

		<Capture
			v-model="isGasPicStartModalOpen"
			:custom-ref="rendRef('pic_elec_system_start')"
			auto-crop-capture
			only-one-crop-mode
			:parent-id="parentId"
			parent-type="location"
			parent-sub-type="location_gas_start"
			@change="handleGasPicStart"
		/>
		<Capture
			v-model="isGasPicEndModalOpen"
			:custom-ref="rendRef('pic_elec_system_end')"
			auto-crop-capture
			only-one-crop-mode
			:parent-id="parentId"
			parent-type="location"
			parent-sub-type="location_gas_end"
			@change="handleGasPicEnd"
		/>
	</div>
</template>

<script>
import meterHelperMixin from './meterHelperMixin';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import SupplierSelector from '@/components/SupplierSelector';
import SupplierEnergyConsumption from './SupplierEnergyConsumption';

export default {
	name: 'LocationServiceGasIncludeComponent',
	components: {
		SupplierEnergyConsumption,
		Capture: () => import('@/components/CaptureModule/Main'),
		SupplierSelector
	},
	mixins: [languageMessages, meterHelperMixin, globalMixin],
	data() {
		return {
			isGasPicStartModalOpen: false,
			isGasPicEndModalOpen: false,
			payload: {
				gasMeterEnd: 0,
				gasMeterEndPict: '',
				gasMeterStart: 0,
				gasMeterStartPict: '',
				gasSupplier: 0,
				consumptionGas: 0
			},

			consumption: 0
		};
	},
	watch: {
		editData: {
			handler(newVal) {
				this.payload = {
					gasMeterEnd: newVal.gasMeterEnd,
					gasMeterEndPict: newVal.gasMeterEndPict,
					gasMeterStart: newVal.gasMeterStart,
					gasMeterStartPict: newVal.gasMeterStartPict,
					gasSupplier: newVal.gasSupplier,
					consumptionGas: newVal.consumptionGas
				};
			},
			immediate: true
		},
		payload: {
			handler(e) {
				this.$emit('change', {
					...e,
					gasMeterEnd: parseFloat(e.gasMeterEnd),
					gasMeterStart: parseFloat(e.gasMeterStart),
					consumptionGas: parseFloat(e.consumptionGas)
				});
			},
			deep: true
		},
		'payload.gasMeterEnd': {
			handler(newVal) {
				this.payload.consumptionGas = parseFloat(newVal) - parseFloat(this.payload.gasMeterStart);
			},
			immediate: true
		}
	},
	methods: {
		handleSupplierUnselect() {
			this.payload.gasSupplier = 0;
		},
		handleSupplierSelectorChange(payload) {
			this.payload.gasSupplier = parseInt(payload.code, 10);
		},
		openGasPicModalStart() {
			this.isGasPicStartModalOpen = true;
		},
		openGasPicModalEnd() {
			this.isGasPicEndModalOpen = true;
		},

		handleGasPicStart(imgs) {
			this.payload.gasMeterStartPict = imgs[0];
			this.save('gasMeterStartPict', imgs[0]);
		},
		handleGasPicEnd(imgs) {
			this.payload.gasMeterEndPict = imgs[0];
			this.save('gasMeterEndPict', imgs[0]);
		}
	}
};
</script>
