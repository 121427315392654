var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "3", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(215, "Waste type"),
                    state: !_vm.$v.dataToSend.type.$error,
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.wasteTypeOptions,
                      state: !_vm.$v.dataToSend.type.$error,
                    },
                    on: { change: _vm.handleChangeType },
                    model: {
                      value: _vm.$v.dataToSend.type.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.dataToSend.type, "$model", $$v)
                      },
                      expression: "$v.dataToSend.type.$model",
                    },
                  }),
                  _vm.$v.dataToSend.type.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(415, "Waste type is required")) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "pt-4",
              attrs: { sm: "3", md: "3", lg: "3", xl: "3" },
            },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    switch: "",
                    value: true,
                    "unchecked-value": false,
                    size: "lg",
                  },
                  model: {
                    value: _vm.dataToSend.boolValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataToSend, "boolValue", $$v)
                    },
                    expression: "dataToSend.boolValue",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center fs-14" },
                    [_vm._v(_vm._s(_vm.FormMSG(298, "Waste sorted")))]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible: _vm.dataToSend.type === 1 && _vm.hideFields === false,
          },
        },
        [
          _vm.dataToSend.type === 1 && _vm.hideFields === false
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(16, "Cardboard") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgCardboardWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgCardboardWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgCardboardWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(17, "kg")) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(16, "Cardboard"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgCardboardWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgCardboardWaste",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dataToSend.kgCardboardWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(29, "kg"))),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(18, "Paper") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgPaperWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgPaperWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgPaperWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(17, "kg")) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(18, "Paper") },
                                  },
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgPaperWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgPaperWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgPaperWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(29, "kg"))),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(19, "Plastic") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgPlasticWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgPlasticWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgPlasticWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(17, "kg")) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(19, "Plastic"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgPlasticWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgPlasticWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgPlasticWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(29, "kg"))),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(20, "Food") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgFoodWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgFoodWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgFoodWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(17, "kg")) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: _vm.FormMSG(20, "Food") } },
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgFoodWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgFoodWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgFoodWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(29, "kg"))),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(324, "Batteries") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.unitBatterieWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "unitBatterieWaste",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataToSend.unitBatterieWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(117, "p")) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(324, "Batteries"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.unitBatterieWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "unitBatterieWaste",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dataToSend.unitBatterieWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(29, "kg"))),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(21, "General waste"),
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgGeneralWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgGeneralWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgGeneralWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(17, "kg")) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(21, "General waste"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgGeneralWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgGeneralWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgGeneralWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(29, "kg"))),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible: _vm.dataToSend.type === 2 && _vm.hideFields === false,
          },
        },
        [
          _vm.dataToSend.type === 2 && _vm.hideFields === false
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(216, "Paint") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgPaintWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgPaintWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgPaintWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(417, "L")) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(216, "Paint") },
                                  },
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgPaintWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgPaintWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgPaintWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(417, "L"))),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(16, "Cardboard") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgCardboardWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgCardboardWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgCardboardWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(17, "kg")) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(16, "Cardboard"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgCardboardWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgCardboardWaste",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dataToSend.kgCardboardWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(29, "kg"))),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(219, "Wood") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgWoodWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgWoodWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgWoodWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(17, "kg")) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(219, "Wood") },
                                  },
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgWoodWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgWoodWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgWoodWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(29, "kg"))),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(220, "Steel") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgSteelWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgSteelWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgSteelWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(17, "kg")) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(220, "Steel") },
                                  },
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgSteelWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgSteelWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgSteelWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(29, "kg"))),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(19, "Plastic") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgPlasticWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgPlasticWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgPlasticWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(17, "kg")) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(19, "Plastic"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgPlasticWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgPlasticWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgPlasticWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(29, "kg"))),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }