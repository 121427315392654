<template>
	<div>
		<b-collapse :visible="!canCompute">
			<b-row v-if="canCompute === false">
				<b-col sm="12">
					<b-alert show variant="warning">
						<component :is="getLucideIcon('AlertTriangle')" :size="16" /> {{ FormMSG(89, 'This transport mean type is not computed by Google') }}
					</b-alert>
				</b-col>
			</b-row>
		</b-collapse>

		<b-row>
			<b-col sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(15, 'Reason for transport')">
					<b-form-select v-model="dataToSend.reason" :options="reasonTransportOptions" @change="handleInputReason" />
				</b-form-group>
			</b-col>
			<b-col sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(45, 'Vehicle')">
					<v-select
						v-model="dataToSend.vehiculeId"
						label="name"
						:options="vehicules"
						:placeholder="FormMSG(85, 'Please select')"
						:reduce="(option) => +option.id"
						:clearable="false"
						:disabled="disableForms"
						@input="handleInputVehiculeId"
					/>
				</b-form-group>
			</b-col>

			<b-col sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(17, 'Number of vehicle')">
					<b-form-input
						v-model="dataToSend.numberOfVehicule"
						:class="{ 'bg-color-grams-hair': disableForms }"
						:disabled="disableForms"
						@change="handleChangeNumberVehicle"
					/>
				</b-form-group>
			</b-col>
		</b-row>

		<b-row v-if="hideTransportTypeSelector === false">
			<b-col sm="12" :md="12" :lg="12" :xl="12">
				<transport-type-selector
					ref="transportTypSelectorCoTwo"
					:data-additional-detail="dataToSend.additionalSubType"
					:data-detail="dataToSend.subType"
					:data-type="dataToSend.type"
					:disable-form="disableForms"
					@transportation-type-selector:change="handleTransportationTypeSelectorChange"
				/>
			</b-col>
		</b-row>

		<b-row v-if="$screen.width <= 576 && hideDistanceKnown === false" class="mt-2 mb-3">
			<b-col sm="12">
				<b-form-checkbox v-model="dataToSend.distanceKnown" size="lg" :disabled="disableForms" switch>
					<div class="d-flex align-items-center fs-14">{{ FormMSG(247, 'Distance known') }}</div>
				</b-form-checkbox>
			</b-col>
		</b-row>
		<b-row>
			<b-col v-if="dataToSend.distanceKnown === false && !hideFromAddress" sm="12" md="4" lg="4" xl="4">
				<b-form-group v-if="useAddressString === false" :label="FormMSG(18, 'From')">
					<v-address
						:params="{ title: FormMSG(48, 'From address transport') }"
						:address-id="dataToSend.fromAddressId"
						:disabled="disableForms"
						@change="handleChangeFromAddress"
						@delete="handleDeleteFromAddress"
					/>
				</b-form-group>
				<b-form-group v-else :label="FormMSG(18, 'From')">
					<b-form-input :value="coTwoData.fromAddress" class="bg-color-grams-hair" disabled />
				</b-form-group>
			</b-col>
			<b-col v-if="dataToSend.distanceKnown === false && !hideToAddress" sm="12" md="4" lg="4" xl="4">
				<b-form-group v-if="useAddressString === false" :label="FormMSG(19, 'To')">
					<v-address
						:params="{ title: FormMSG(58, 'To address transport') }"
						:address-id="dataToSend.toAddressId"
						:disabled="disableForms"
						@change="handleChangeToAddress"
						@delete="handleDeleteToAddress"
					/>
				</b-form-group>
				<b-form-group v-else :label="FormMSG(19, 'To')">
					<b-form-input :value="coTwoData.toAddress" class="bg-color-grams-hair" disabled />
				</b-form-group>
			</b-col>

			<b-col v-if="hideDistance === false" sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(20, 'Distance')">
					<b-input-group>
						<b-form-input
							v-model="dataToSend.km"
							:class="{ 'bg-color-grams-hair': !dataToSend.distanceKnown }"
							:disabled="!dataToSend.distanceKnown || disableForms"
							@change="handleChangeDistance"
						/>
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ distanceUnit }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-90 mr-3">
						<b-form-group :label="FormMSG(20, 'Distance')">
							<b-form-input
								v-model="dataToSend.km"
								:class="{ 'bg-color-grams-hair': !dataToSend.distanceKnown }"
								:disabled="!dataToSend.distanceKnown || disableForms"
								@change="handleChangeDistance"
							/>
						</b-form-group>
					</div>
					<div class="d-flex align-items-center pt-3 text-capitalize">
						<div class="fs-20 fw-700">{{ distanceUnit }}</div>
					</div>
				</div>
			</b-col>
			<b-col v-if="[9, 10, 11, 12, 19].includes(transportTypeMapped)" sm="12" md="4" lg="4" xl="4">
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(21, 'Material weight (if Freight)')">
					<b-input-group>
						<b-form-input v-model="dataToSend.number" :disabled="disableForms" @change="handleChangeMaterialWeight" />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(29, 'kg') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-90 mr-3">
						<b-form-group :label="FormMSG(21, 'Material weight (if Freight)')">
							<b-form-input v-model="dataToSend.number" :disabled="disableForms" @change="handleChangeMaterialWeight" />
						</b-form-group>
					</div>
					<div class="d-flex align-items-center pt-3 text-capitalize">
						<div class="fs-20 fw-700">{{ FormMSG(29, 'kg') }}</div>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import _ from 'lodash';
import { getVehicules, getVehicule } from '@/cruds/vehicules.crud';
import { getAddress } from '@/cruds/address.crud';
import { fetchDistance } from '~helpers';
import { getTravelModeFromTransportMean, setDurationToDate } from '@/modules/transports/utils';
import MainAddressLocation from '@/components/LocationService/MainAddressLocation';
import moment from 'moment';
import TransportTypeSelector from '@/modules/transports/components/TransportTypeSelector.vue';

const EXCEPTION_TO_COMPUTE = [0, 5, 7, 9, 18, 19, 20, 41, 42, 43];

export default {
	name: 'CoTwoTransportForm',

	mixins: [languageMessages, globalMixin],

	props: {
		isDistanceKnown: { type: Boolean, default: false, required: false },
		coTwoData: { type: Object, default: () => ({}), required: false },
		hideToAddress: { type: Boolean, default: false, required: false },
		hideFromAddress: { type: Boolean, default: false, required: false },
		disableForms: { type: Boolean, default: false, required: false },
		useAddressString: { type: Boolean, default: false, required: false },
		hideDistanceKnown: { type: Boolean, default: false, required: false },
		hideTransportTypeSelector: { type: Boolean, default: false, required: false },
		hideDistance: { type: Boolean, default: false, required: false },
		getMyVehicules: { type: Boolean, default: false, required: false }
	},

	components: {
		'v-address': MainAddressLocation,
		TransportTypeSelector
	},

	data() {
		return {
			vehicules: [],
			dataToSend: {
				vehiculeId: null,
				type: 0,
				subType: 0,
				additionalSubType: 0,
				reason: null,
				numberOfVehicule: 1,
				distanceKnown: false,
				materialWeight: 0,
				km: 0,
				fromAddressId: 0,
				toAddressId: 0,
				number: 0
			},

			transportTypeMapped: 0,
			vehiculeSelected: null,

			canCompute: true
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend.vehiculeId = +newVal.vehiculeId === 0 ? null : +newVal.vehiculeId;
					this.dataToSend.type = +newVal.type;
					this.dataToSend.subType = +newVal.subType;
					this.dataToSend.additionalSubType = +newVal.additionalSubType;
					this.dataToSend.reason = +newVal.reason;
					this.dataToSend.numberOfVehicule = +newVal.numberOfVehicule;
					// this.dataToSend.distanceKnown = newVal.boolValue;
					this.dataToSend.number = parseFloat(newVal.number);
					this.dataToSend.km = +newVal.km;
					this.dataToSend.fromAddressId = +newVal.fromAddressId;
					this.dataToSend.toAddressId = +newVal.toAddressId;
					// this.dataToSend.number = parseFloat(newVal.number);
					// this.vehiculeSelected = `${newVal.vehiculeId}`;

					this.transportTypeMapped = this.mappedTransportTypeToCarbonClapValue(
						this.dataToSend.type,
						this.dataToSend.subType,
						this.dataToSend.additionalSubType
					);
				}
			},
			immediate: true,
			deep: true
		},
		isDistanceKnown: {
			handler(newVal) {
				this.dataToSend.distanceKnown = _.cloneDeep(newVal);
			},
			immediate: true,
			deep: true
		},
		transportTypeMapped: {
			handler(newVal) {
				if (EXCEPTION_TO_COMPUTE.includes(newVal) === false) {
					this.canCompute = true;
				} else {
					this.canCompute = false;
				}
			},
			deep: true
		}
	},

	computed: {
		transportationMeanOptions() {
			let result = this.FormMenu(905);

			if (store.state.myProject.carbonModel === 2) {
				result = result.filter((option) => option.value < 41);
			}

			result = result.sort(function compare(a, b) {
				if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
				if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
				return 0;
			});

			return result;
		},
		reasonTransportOptions() {
			return this.FormMenu(10007);
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit.toLowerCase();
		}
	},

	async created() {
		await this.getVehicules();
	},

	methods: {
		isInvalid() {
			return this.$refs['transportTypSelectorCoTwo'].isInvalid();
		},
		handleTransportationTypeSelectorChange({ type, detail, additionalDetail }) {
			this.dataToSend.type = type;
			this.dataToSend.subType = detail;
			this.dataToSend.additionalSubType = additionalDetail;

			this.transportTypeMapped = this.mappedTransportTypeToCarbonClapValue(
				this.dataToSend.type,
				this.dataToSend.subType,
				this.dataToSend.additionalSubType
			);

			this.emitChange();
		},
		handleChangeMaterialWeight() {
			this.emitChange();
		},
		handleChangeDistance() {
			this.emitChange();
		},
		handleChangeNumberVehicle() {
			this.emitChange();
		},
		handleInputReason() {
			this.emitChange();
		},
		async handleInputVehiculeId(payload) {
			this.dataToSend.vehiculeId = payload;

			const resultVehicule = await getVehicule(+payload);

			this.dataToSend.type = resultVehicule.type;
			this.dataToSend.subType = resultVehicule.subType;
			this.dataToSend.additionalSubType = resultVehicule.additionalSubType;

			this.emitChange();
		},
		async plannificationGoogle() {
			const fromAddressId = this.dataToSend.fromAddressId;
			const toAddressId = this.dataToSend.toAddressId;

			if (fromAddressId === 0 || toAddressId === 0) return;

			const from = await getAddress(fromAddressId);
			const to = await getAddress(toAddressId);

			const travel = getTravelModeFromTransportMean(this.transportTypeMapped);

			const dateTimeField = 'departureTime';

			try {
				const distancePayload = {
					from,
					to,
					...travel,
					avoidHighways: false,
					avoidTolls: false,
					[dateTimeField]: new Date().toISOString()
				};

				const res = await fetchDistance(distancePayload);

				const elem = res.rows[0].elements[0];
				const distanceInKm = elem.distance.value / 1000;

				this.dataToSend.km = distanceInKm;
			} catch (e) {
				this.durationEstimation = {
					status: 'error',
					message: e,
					estimation: null
				};
			}
		},
		async handleChangeToAddress(payload) {
			this.dataToSend.toAddressId = +payload.id;

			if (
				this.dataToSend.fromAddressId > 0 &&
				this.dataToSend.toAddressId > 0 &&
				this.transportTypeMapped > 0 &&
				!EXCEPTION_TO_COMPUTE.includes(this.transportTypeMapped)
			) {
				await this.plannificationGoogle(true);
			}

			this.emitChange();
		},
		handleDeleteToAddress() {
			this.dataToSend.toAddressId = 0;
			this.dataToSend.km = 0;

			this.emitChange();
		},
		async handleDeleteFromAddress() {
			this.dataToSend.fromAddressId = 0;
			this.dataToSend.km = 0;

			this.emitChange();
		},
		async handleChangeFromAddress(payload) {
			this.dataToSend.fromAddressId = +payload.id;

			if (
				this.dataToSend.fromAddressId > 0 &&
				this.dataToSend.toAddressId > 0 &&
				this.transportTypeMapped > 0 &&
				!EXCEPTION_TO_COMPUTE.includes(this.transportTypeMapped)
			) {
				await this.plannificationGoogle(true);
			}

			this.emitChange();
		},
		emitChange() {
			this.dataToSend.vehiculeId = +this.dataToSend.vehiculeId;
			this.dataToSend.type = +this.dataToSend.type;
			this.dataToSend.reason = +this.dataToSend.reason;
			this.dataToSend.numberOfVehicule = +this.dataToSend.numberOfVehicule;
			this.dataToSend.materialWeight = +this.dataToSend.materialWeight;
			this.dataToSend.km = +this.dataToSend.km;
			this.dataToSend.fromAddressId = +this.dataToSend.fromAddressId;
			this.dataToSend.toAddressId = +this.dataToSend.toAddressId;
			this.dataToSend.subType = +this.dataToSend.subType;
			this.dataToSend.additionalSubType = +this.dataToSend.additionalSubType;
			this.dataToSend.number = parseFloat(this.dataToSend.number);

			this.$emit('co-two-transport:change', this.dataToSend);
		},
		async getVehicules() {
			if (this.getMyVehicules === false) {
				this.vehicules = await getVehicules(true, 0);
			} else {
				this.vehicules = await getVehicules(false, store.userID());
			}
		}
	}
};
</script>

<style scoped></style>
