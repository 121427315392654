<template>
	<div>
		<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside bg-color-white border-ligth-starlight' : ''}`">
			<legend v-if="$screen.width <= 576" :class="`${$screen.width <= 576 ? 'card-inside' : ''}`" class="text-color-rhapsody-in-blue-2">
				{{ FormMSG(653, 'Electricity') }}
			</legend>
			<div :class="{ 'pt-1 pb-1': $screen.width <= 576 }">
				<b-row class="d-flex align-items">
					<b-col v-if="$screen.width > 576" sm="12" md="2" lg="2" xl="2">
						<b-form-group :label="FormMSG(632, 'Type')">
							<b-form-input :value="FormMSG(653, 'Electricity')" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<supplier-energy-consumption
							:label="FormMSG(633, 'Supplier')"
							:dialog-title="FormMSG(253, 'Electricity supplier')"
							supplier-type="electricity"
							:label-add-supplier="FormMSG(198, 'New electricity supplier')"
							:supplier-id="payload.elecSupplier"
							@supplier-energy:change="handleSupplierSelectorChange"
							@supplier-energy:unselect="handleSupplierUnselect"
						/>
					</b-col>
					<b-col sm="12" md="2" lg="2" xl="2">
						<b-form-group :label="FormMSG(634, 'Meter start')">
							<b-input-group>
								<b-form-input v-model="payload.elecMeterStart" class="br-none" />
								<b-input-group-append>
									<b-input-group-text class="bg-color-white input-group-text-bg-white">
										<div class="d-flex">
											<button
												v-if="has(payload.elecMeterStartPict) && payload.elecMeterStartPict !== ''"
												@click="showPicture([payload.elecMeterStartPict])"
												class="btn-transparent text-color-blue-rinse"
											>
												<component :is="getLucideIcon('Image')" :size="20" />
											</button>
											<button v-if="!$isPwa()" class="btn-transparent text-color-rhapsody-in-blue" @click="openElectPicModalStart">
												<component :is="getLucideIcon('Paperclip')" :size="20" />
											</button>
											<button
												v-if="$isPwa()"
												class="btn-transparent text-color-rhapsody-in-blue"
												@click="startMobilePwaCapture('handleElectPicStart')"
											>
												<component :is="getLucideIcon('Paperclip')" :size="20" />
											</button>
										</div>
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="2" lg="2" xl="2">
						<b-form-group :label="FormMSG(635, 'Meter end')">
							<b-input-group>
								<b-form-input v-model="payload.elecMeterEnd" class="br-none" />
								<b-input-group-append>
									<b-input-group-text class="bg-color-white input-group-text-bg-white">
										<div class="d-flex">
											<button
												class="btn-transparent text-color-blue-rinse"
												v-if="has(payload.elecMeterEndPict) && payload.elecMeterEndPict !== ''"
												@click="showPicture([payload.elecMeterEndPict])"
											>
												<component :is="getLucideIcon('Image')" :size="20" />
											</button>
											<button v-if="!$isPwa()" class="btn-transparent text-color-rhapsody-in-blue" @click="openElectPicModalEnd">
												<component :is="getLucideIcon('Paperclip')" :size="20" />
											</button>
											<button
												v-if="$isPwa()"
												class="btn-transparent text-color-rhapsody-in-blue"
												@click="startMobilePwaCapture('handleElectPicEnd')"
											>
												<component :is="getLucideIcon('Paperclip')" :size="20" />
											</button>
										</div>
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="2" lg="2" xl="2">
						<b-form-group :label="FormMSG(75, 'Consumption')">
							<b-input-group>
								<b-form-input v-model="payload.consumptionElectricity" />
								<b-input-group-append>
									<b-input-group-text class="bg-color-north-wind text-color-white">
										{{ FormMSG(259, 'kWh') }}
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<!--					<b-col sm="12" md="2" lg="2" xl="2" class="d-flex align-items-center">-->
					<!--						<b-form-group>-->
					<!--							<label class="pb-2">&nbsp;</label>-->
					<!--							<b-form-checkbox size="lg">-->
					<!--								<div class="d-flex align-items-center">-->
					<!--									<div class="fs-12" style="margin-top: 4px">{{ FormMSG(481, 'Unknown') }}&nbsp;</div>-->
					<!--									<info-tooltip>-->
					<!--										{{-->
					<!--											FormMSG(-->
					<!--												485,-->
					<!--												'Select “unknown” if you don’t have a seperate counter for electricity. The electricity consumption will then be evaluated considering the filming area and the number of filming days.'-->
					<!--											)-->
					<!--										}}-->
					<!--									</info-tooltip>-->
					<!--								</div>-->
					<!--							</b-form-checkbox>-->
					<!--						</b-form-group>-->
					<!--					</b-col>-->
				</b-row>
			</div>
		</fieldset>

		<Capture
			:custom-ref="rendRef('pic_elec_system_start')"
			v-model="isElectPicStartModalOpen"
			auto-crop-capture
			:parentId="parentId"
			parentType="location"
			parentSubType="location_elect_start"
			@change="handleElectPicStart"
		/>
		<Capture
			:custom-ref="rendRef('pic_elec_system_end')"
			v-model="isElectPicEndModalOpen"
			auto-crop-capture
			:parentId="parentId"
			parentType="location"
			parentSubType="location_elect_end"
			@change="handleElectPicEnd"
		/>
	</div>
</template>

<script>
import meterHelperMixin from './meterHelperMixin';
// import showPictureMixin from '@/mixins/show-picture.mixins'
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import SupplierSelector from '@/components/SupplierSelector';
import SupplierEnergyConsumption from './SupplierEnergyConsumption';
import InfoTooltip from '@/components/LocationService/InfoTooltip';

export default {
	name: 'LocationServiceElectIncludeComponent',
	components: {
		SupplierEnergyConsumption,
		Capture: () => import('@/components/CaptureModule/Main'),
		SupplierSelector,
		InfoTooltip
	},
	mixins: [languageMessages, meterHelperMixin, globalMixin],
	data() {
		return {
			isElectPicStartModalOpen: false,
			isElectPicEndModalOpen: false,
			payload: {
				elecMeterEnd: 0,
				elecMeterEndPict: '',
				elecMeterStart: 0,
				elecMeterStartPict: '',
				elecSupplier: 0,
				consumptionElectricity: 0
			},
			consumption: 0
		};
	},
	watch: {
		editData: {
			handler(newVal) {
				this.payload = {
					elecMeterEnd: newVal.elecMeterEnd,
					elecMeterEndPict: newVal.elecMeterEndPict,
					elecMeterStart: newVal.elecMeterStart,
					elecMeterStartPict: newVal.elecMeterStartPict,
					elecSupplier: newVal.elecSupplier,
					consumptionElectricity: newVal.consumptionElectricity
				};
			},
			immediate: true
		},
		payload: {
			handler(e) {
				this.$emit('change', {
					...e,
					elecMeterEnd: parseFloat(e.elecMeterEnd),
					elecMeterStart: parseFloat(e.elecMeterStart),
					consumptionElectricity: parseFloat(e.consumptionElectricity)
				});
			},
			deep: true
		},
		'payload.elecMeterEnd': {
			handler(newVal) {
				this.payload.consumptionElectricity = parseFloat(newVal) - parseFloat(this.payload.elecMeterStart);
			},
			immediate: true
		}
	},
	methods: {
		handleSupplierUnselect() {
			this.payload.elecSupplier = 0;
		},
		handleSupplierSelectorChange(payload) {
			this.payload.elecSupplier = parseInt(payload.code, 10);
		},
		openElectPicModalStart() {
			this.isElectPicStartModalOpen = true;
		},
		openElectPicModalEnd() {
			this.isElectPicEndModalOpen = true;
		},

		/**
		 * @param {Array} imgs
		 */
		handleElectPicStart(imgs) {
			this.payload.elecMeterStartPict = imgs[0];
			this.save('elecMeterStartPict', imgs[0]);
		},
		/**
		 * @param {Array} imgs
		 */
		handleElectPicEnd(imgs) {
			this.payload.elecMeterEndPict = imgs[0];
			this.save('elecMeterEndPict', imgs[0]);
		}
	}
};
</script>
