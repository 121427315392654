<template>
	<div>
		<b-row v-if="!hideCoTwoCategorySelector">
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(14, 'CO2 type')" class="mb-1">
					<b-form-select
						v-model="dataToSend.coTwoCategory"
						:options="coTwoTypeOptions"
						:placeholder="FormMSG(85, 'Select CO2 type')"
						:disabled="disableCarbonTypeSelector"
						@change="handleChangeCoTwoCategory"
					/>
				</b-form-group>
				<div v-if="!forCarbonTemplate" class="text-color-blue-streak d-flex align-items-center">
					<component :is="getLucideIcon('Info')" :size="16" />
					<div class="ml-1" style="margin-top: 3px">{{ infoText }}</div>
				</div>
			</b-col>
			<b-col v-if="dataToSend.coTwoCategory === 1 || dataToSend.coTwoCategory === 15" sm="12" md="4" lg="4" xl="4">
				<b-form-group>
					<label for="type" class="pb-0 mb-0">
						<div class="d-flex align-items-center fs-12">
							{{ FormMSG(631, 'Energy saving plan') }}&nbsp;
							<info-tooltip style="margin-bottom: 2px">
								{{ FormMSG(643, 'Have you implemented the Ademe energy saving plan?  Information can be found here:') }}
								<a target="blank" href="https://agirpourlatransition.ademe.fr/entreprises/sobriete-energetique-entreprises">{{
									FormMSG(644, 'Ademe wabsite')
								}}</a>
							</info-tooltip>
						</div>
					</label>
					<b-form-select v-model="energySavingSelected" :options="energySavingOptions" @change="handleOptionSelectedEnergySaving" />
				</b-form-group>
			</b-col>
			<b-col
				v-if="dataToSend.coTwoCategory === 4 && $screen.width > 576 && hideDistanceKnownTransport === false"
				sm="6"
				md="4"
				lg="4"
				xl="4"
				class="pt-4"
			>
				<b-form-checkbox v-model="transportDistanceKnown" size="lg" switch @change="handleChangeDistanceKnown">
					<div class="d-flex align-items-center fs-14" style="margin-top: 2px">{{ FormMSG(247, 'Distance known') }}</div>
				</b-form-checkbox>
			</b-col>
			<!--			<b-col v-if="dataToSend.coTwoCategory === 5" sm="3" md="3" lg="3" xl="3">-->
			<!--				<b-form-group :label="FormMSG(215, 'Waste type')">-->
			<!--					<b-form-select v-model="wasteTypeSelected" :options="wasteTypeOptions" />-->
			<!--				</b-form-group>-->
			<!--			</b-col>-->
			<!--			<b-col v-if="dataToSend.coTwoCategory === 5" sm="3" md="3" lg="3" xl="3" class="pt-4">-->
			<!--				<b-form-checkbox v-model="wasteSorted" switch :value="true" :unchecked-value="false" size="lg">-->
			<!--					<div class="d-flex align-items-center fs-14">{{ FormMSG(298, 'Waste sorted') }}</div>-->
			<!--				</b-form-checkbox>-->
			<!--			</b-col>-->
		</b-row>

		<b-collapse :visible="dataToSend.coTwoCategory === 1 && !forCarbonTemplate">
			<div v-if="dataToSend.coTwoCategory === 1 && !forCarbonTemplate" class="mt-2">
				<co-two-location-form :co-two-data="carbonData" @co-two-location:change="handleCoTwoLocationChange" />
			</div>
		</b-collapse>

		<b-collapse :visible="dataToSend.coTwoCategory === 2">
			<div v-if="dataToSend.coTwoCategory === 2" class="mt-2">
				<co-two-accomodation-form
					ref="coTwoAccommodationFormRef"
					:co-two-data="carbonData"
					:hide-number-of-bedroom="forCarbonTemplate"
					:hide-number-of-night="forCarbonTemplate"
					:hide-total-number-of-night="forCarbonTemplate"
					:disable-forms="disableAccomodationForms"
					@co-two-accomodation:change="handleCoTwoAccomodationChange"
				/>
			</div>
		</b-collapse>

		<b-collapse :visible="dataToSend.coTwoCategory === 3 && !forCarbonTemplate">
			<div v-if="dataToSend.coTwoCategory === 3 && !forCarbonTemplate" class="mt-2">
				<co-two-energy-form ref="coTwoEnergyFormRef" :co-two-data="carbonData" @co-two-energy:change="handleCoTwoEnergyChange" />
			</div>
		</b-collapse>
		<b-collapse :visible="dataToSend.coTwoCategory === 4">
			<div v-if="dataToSend.coTwoCategory === 4" class="mt-2">
				<co-two-transport-form
					ref="coTwoTransportFormRef"
					:co-two-data="carbonData"
					:is-distance-known="transportDistanceKnown"
					:hide-from-address="forCarbonTemplate || hideFromAddressTransport"
					:hide-to-address="forCarbonTemplate || hideToAddressTransport"
					:disable-forms="disableTransportForms"
					:use-address-string="useAddressTransportString"
					:hide-distance-known="hideDistanceKnownTransport"
					:hide-transport-type-selector="hideTransportTypeSelectorTransport"
					:hide-distance="hideDistanceTransport"
					:get-my-vehicules="getMyVehiculeInTransport"
					@co-two-transport:change="handleCoTwoTransportChange"
				/>
			</div>
		</b-collapse>
		<b-collapse :visible="dataToSend.coTwoCategory === 6 && !forCarbonTemplate">
			<div v-if="dataToSend.coTwoCategory === 6 && !forCarbonTemplate" class="mt-2">
				<co-two-meal-form :co-two-data="carbonData" @co-two-meal:change="handleCoTwoMealChange" />
			</div>
		</b-collapse>
		<b-collapse :visible="dataToSend.coTwoCategory === 7">
			<div v-if="dataToSend.coTwoCategory === 7" class="mt-2">
				<co-two-hmc-form :co-two-data="carbonData" :for-carbon-template="forCarbonTemplate" @co-two-hmc:change="handleCoTwoHmcChange" />
			</div>
		</b-collapse>
		<b-collapse :visible="dataToSend.coTwoCategory === 8">
			<div v-if="dataToSend.coTwoCategory === 8" class="mt-2">
				<co-two-set-creation-form
					:co-two-data="carbonData"
					:for-carbon-template="forCarbonTemplate"
					@co-two-set-creation:change="handleCoTwoSetCreationChange"
				/>
			</div>
		</b-collapse>
		<b-collapse :visible="dataToSend.coTwoCategory === 9">
			<div v-if="dataToSend.coTwoCategory === 9" class="mt-2">
				<co-two-tehnical-production-form
					:co-two-data="carbonData"
					:for-carbon-template="forCarbonTemplate"
					@co-two-tehnical-production:change="handleCoTwoTechnicalProductionChange"
				/>
			</div>
		</b-collapse>
		<b-collapse :visible="dataToSend.coTwoCategory === 10 && !forCarbonTemplate">
			<div v-if="dataToSend.coTwoCategory === 10 && !forCarbonTemplate" class="mt-2">
				<co-two-transportation-shooting-form
					:co-two-data="carbonData"
					@co-two-transportation-shooting:change="handleCoTwoTransportationShootingChange"
				/>
			</div>
		</b-collapse>
		<b-collapse :visible="dataToSend.coTwoCategory === 5">
			<div v-if="dataToSend.coTwoCategory === 5" class="mt-2">
				<co-two-waste-form
					ref="coTwoWasteFormRef"
					:co-two-data="carbonData"
					:hide-fields="forCarbonTemplate"
					@co-two-waste:change="handleCoTwoWasteChange"
				/>
			</div>
		</b-collapse>
		<b-collapse :visible="dataToSend.coTwoCategory === 11">
			<div v-if="dataToSend.coTwoCategory === 11" class="mt-2">
				<co-two-generator-energy-form
					:co-two-data="carbonData"
					:for-carbon-template="forCarbonTemplate"
					@co-two-generator-energy:change="handleCoTwoGeneratorEnergyChange"
				/>
			</div>
		</b-collapse>
		<b-collapse :visible="dataToSend.coTwoCategory === 12 && !forCarbonTemplate">
			<div v-if="dataToSend.coTwoCategory === 12 && !forCarbonTemplate" class="mt-2">
				<co-two-transport-car-lodges-form
					ref="coTwoTransportCarLodgesRef"
					:co-two-data="carbonData"
					@co-two-transport-car-lodges:change="handleCoTwoTransportCarLodgesChange"
				/>
			</div>
		</b-collapse>
		<b-collapse :visible="dataToSend.coTwoCategory === 13">
			<div v-if="dataToSend.coTwoCategory === 13" class="mt-2">
				<co-two-fuel-form :co-two-data="carbonData" :hide-quantity="forCarbonTemplate" @co-two-fuel:change="handleCoTwoFuelChange" />
			</div>
		</b-collapse>
		<b-collapse :visible="dataToSend.coTwoCategory === 14">
			<div v-if="dataToSend.coTwoCategory === 14" class="mt-2">
				<co-two-production-logistic :co-two-data="carbonData" @co-two-production-logistic:change="handleCoTwoProductionLogistic" />
			</div>
		</b-collapse>
		<b-collapse :visible="dataToSend.coTwoCategory === 15 && !forCarbonTemplate">
			<div v-if="dataToSend.coTwoCategory === 15 && !forCarbonTemplate" class="mt-2">
				<co-two-premise-form :co-two-data="carbonData" @co-two-premise:change="handleCoTwoPremiseChange" />
			</div>
		</b-collapse>
		<b-row v-if="hideBtnReviewed === false">
			<b-col>
				<b-row>
					<b-col
						v-if="parentId > 0 && [0, 1, 2].includes(dataToSend.verified)"
						sm="12"
						md="5"
						offset-md="7"
						lg="5"
						offset-lg="7"
						xl="5"
						offset-xl="7"
						class="mt-3"
					>
						<b-button variant="blue-sapphire" block :disabled="loadingForMarkReview" @click="handleClickReviewed">
							<component v-if="loadingForMarkReview === false" :is="getLucideIcon('CheckCircle')" :size="16" />
							<b-spinner v-if="loadingForMarkReview === true" small />
							{{ FormMSG(781, 'Mark as reviewed and go to next') }}
						</b-button>
					</b-col>
					<b-col v-if="parentId > 0 && dataToSend.verified === 3" sm="12" md="5" offset-md="7" lg="5" offset-lg="7" xl="5" offset-xl="7" class="mt-3">
						<div class="text-primary fs-18 float-right">
							<component :is="getLucideIcon('Check')" :size="20" /> {{ FormMSG(812, 'Already reviewed') }}
						</div>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

import CoTwoAccomodationForm from './form/CoTwoAccomodationForm';
import InfoTooltip from '@/components/LocationService/InfoTooltip';
import CoTwoEnergyForm from './form/CoTwoEnergyForm';
import CoTwoTransportForm from './form/CoTwoTransportForm';
import CoTwoMealForm from './form/CoTwoMealForm';
import CoTwoHmcForm from './form/CoTwoHmcForm';
import CoTwoSetCreationForm from './form/CoTwoSetCreationForm';
import CoTwoTehnicalProductionForm from './form/CoTwoTehnicalProductionForm';
import CoTwoTransportationShootingForm from './form/CoTwoTransportationShootingForm';
import CoTwoWasteForm from './form/CoTwoWasteForm';
import CoTwoGeneratorEnergyForm from './form/CoTwoGeneratorEnergyForm';
import CoTwoTransportCarLodgesForm from './form/CoTwoTransportCarLodgesForm';
import CoTwoFuelForm from './form/CoTwoFuelForm';
import CoTwoLocationForm from './form/CoTwoLocationForm.vue';

import { getCarbon, newCarbon, updCarbon } from '@/cruds/carbon.crud';
import { store } from '@/store';
import CurrencySvg from '@/components/Svg/Currency';
import { INIT_DATA_CARBON } from '@/shared/constants';

import _ from 'lodash';
import CoTwoProductionLogistic from './form/CoTwoProductionLogistic.vue';
import CoTwoPremiseForm from './form/CoTwoPremiseForm.vue';

export default {
	name: 'CoTwoClap',

	components: {
		CoTwoPremiseForm,
		CoTwoProductionLogistic,
		CoTwoFuelForm,
		CoTwoTransportCarLodgesForm,
		CoTwoGeneratorEnergyForm,
		CoTwoWasteForm,
		CoTwoTransportationShootingForm,
		CoTwoTehnicalProductionForm,
		CoTwoSetCreationForm,
		CoTwoHmcForm,
		CoTwoMealForm,
		CoTwoTransportForm,
		CoTwoEnergyForm,
		CoTwoAccomodationForm,
		CoTwoLocationForm,
		InfoTooltip,
		CurrencySvg
	},

	props: {
		parentId: { type: [String, Number], required: false },
		entityType: { type: Number, default: 1, required: false },
		isSubmit: { type: Boolean, default: false, required: false },
		forCreate: { type: Boolean, default: true, required: false },
		hideBtnReviewed: { type: Boolean, default: false, required: false },
		externalData: { type: Object, default: () => ({}), required: false },
		forCarbonTemplate: { type: Boolean, default: false, required: false },
		externalCarbonData: { type: Object, default: () => ({}), required: false },
		hideLocationMenu: { type: Boolean, default: false, required: false },
		hidePremiseMenu: { type: Boolean, default: false, required: false },
		disableTransportForms: { type: Boolean, default: false, required: false },
		disableAccomodationForms: { type: Boolean, default: false, required: false },
		disableCarbonTypeSelector: { type: Boolean, default: false, required: false },
		useAddressTransportString: { type: Boolean, default: false, required: false },
		hideDistanceKnownTransport: { type: Boolean, default: false, required: false },
		hideTransportTypeSelectorTransport: { type: Boolean, default: false, required: false },
		hideFromAddressTransport: { type: Boolean, default: false, required: false },
		hideToAddressTransport: { type: Boolean, default: false, required: false },
		hideDistanceTransport: { type: Boolean, default: false, required: false },
		/**
		 * Flag to select only my personnal car
		 */
		getMyVehiculeInTransport: { type: Boolean, default: false, required: false },
		hideCoTwoCategorySelector: { type: Boolean, default: false, required: false }
	},

	mixins: [languageMessages, globalMixin],

	data() {
		return {
			coTwoTypeSelected: null,
			energySavingSelected: null,
			transportDistanceKnown: false,

			dataToSend: _.cloneDeep(INIT_DATA_CARBON),
			wasteSorted: false,

			carbonData: _.cloneDeep(INIT_DATA_CARBON),
			firstLoad: true,
			wasteTypeSelected: 0,

			loadingForMarkReview: false
		};
	},

	destroyed() {
		this.coTwoTypeSelected = null;
		this.energySavingSelected = null;
		this.transportDistanceKnown = false;
		this.dataToSend = INIT_DATA_CARBON;
		this.carbonData = {};
		this.firstLoad = true;
		this.wasteTypeSelected = 0;
	},

	watch: {
		externalCarbonData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend = _.cloneDeep(newVal);
					this.carbonData = _.cloneDeep(newVal);

					this.coTwoTypeSelected = newVal.coTwoCategory;
					this.energySavingSelected = newVal.type;
					this.wasteTypeSelected = newVal.type;
					this.wasteSorted = newVal.boolValue;
					this.transportDistanceKnown = newVal.boolValue;
					this.dataToSend.amount = newVal.amount;
				}
			},
			immediate: true,
			deep: true
		},
		externalData: {
			handler(newVal) {
				if (this.forCarbonTemplate === true && JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend.coTwoCategory = newVal.coTwoCategory;
					this.dataToSend.verified = false;
					this.dataToSend.boolValue = newVal.boolValue;
					this.dataToSend.type = newVal.type;
					this.dataToSend.boolValue = newVal.boolValue;
					this.dataToSend.parentId = 0;
					this.dataToSend.km = newVal.km;
					this.dataToSend.subType = newVal.subType;
					this.dataToSend.liter = newVal.liter;
					this.dataToSend.mcube = newVal.mcube;
					this.dataToSend.number = newVal.number;
					this.dataToSend.additionalSubType = newVal.additionalSubType;
					this.dataToSend.reason = newVal.reason;

					this.coTwoTypeSelected = newVal.coTwoCategory;
					this.energySavingSelected = newVal.type;
					this.wasteTypeSelected = newVal.type;
					this.wasteSorted = newVal.boolValue;
					this.transportDistanceKnown = newVal.boolValue;
					this.dataToSend.amount = 0;

					this.constructCarbonDataManually(newVal);
				}
			},
			immediate: true,
			deep: true
		},
		isSubmit: {
			async handler(newVal) {
				await this.submit();
			},
			deep: true
		}
	},

	computed: {
		wasteTypeOptions() {
			return [
				{ value: 1, text: this.FormMSG(547, 'Standard') },
				{ value: 2, text: this.FormMSG(548, 'Set destruction') }
			];
		},
		infoText() {
			switch (this.coTwoTypeSelected) {
				case 2:
					return this.FormMSG(458, 'Detail of your expense');
				case 4:
					return this.FormMSG(455, 'Detail on your transport');
				case 6:
					return this.FormMSG(457, 'Specify the number of meals per type');
				default:
					return this.FormMSG(458, 'Detail of your expense');
			}
		},
		energySavingOptions() {
			return this.FormMenu(1359);
		},
		coTwoTypeOptions() {
			let result = this.FormMenu(1334);

			if (this.hideLocationMenu === true) {
				result = result.filter((option) => option.value !== 1);
			}

			if (this.hidePremiseMenu === true) {
				result = result.filter((option) => option.value !== 15);
			}

			result = result.filter((option) => option.value !== 11);

			return result;
		}
	},
	methods: {
		isInvalid() {
			if (this.dataToSend.coTwoCategory === 4) {
				if (this.hideTransportTypeSelectorTransport === false) {
					return this.$refs['coTwoTransportFormRef'].isInvalid();
				}
				return false;
			} else if (this.dataToSend.coTwoCategory === 2) {
				return this.$refs['coTwoAccommodationFormRef'].isInvalid();
			} else if (this.dataToSend.coTwoCategory === 3) {
				return this.$refs['coTwoEnergyFormRef'].isInvalid();
			} else if (this.dataToSend.coTwoCategory === 12) {
				return this.$refs['coTwoTransportCarLodgesRef'].isInvalid();
			} else if (this.dataToSend.coTwoCategory === 5) {
				return this.$refs['coTwoWasteFormRef'].isInvalid();
			}

			return false;
		},
		handleCoTwoPremiseChange(payload) {
			this.resetDataToSend();

			this.dataToSend = {
				...this.dataToSend,
				..._.omit(payload.elecData, ['elecMeterEndPict', 'elecMeterStartPict']),
				..._.omit(payload.gasData, ['gasMeterEndPict', 'gasMeterStartPict']),
				..._.omit(payload.waterData, ['waterMeterEndPict', 'waterMeterStartPict']),
				..._.omit(payload.generatorData, ['generatorPict']),
				greenEnergySupplier: payload.greenEnergySupplier,
				recycledPaper: payload.recycledPaper,
				sortedWaste: payload.sortedWaste,
				paperPrinted: payload.paperPrinted
			};

			this.emitChange();
		},
		handleCoTwoProductionLogistic(payload) {
			this.resetDataToSend();

			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleCoTwoLocationChange(payload) {
			this.resetDataToSend();

			this.dataToSend = {
				...this.dataToSend,
				..._.omit(payload.elecData, ['elecMeterEndPict', 'elecMeterStartPict']),
				..._.omit(payload.gasData, ['gasMeterEndPict', 'gasMeterStartPict']),
				..._.omit(payload.waterData, ['waterMeterEndPict', 'waterMeterStartPict']),
				..._.omit(payload.generatorData, ['generatorPict'])
			};

			this.emitChange();
		},
		handleChangeDistanceKnown() {
			this.emitChange();
		},
		handleOptionSelectedEnergySaving() {
			this.emitChange();
		},
		async handleClickReviewed() {
			this.loadingForMarkReview = true;

			// await this.submit(false);

			// await updCarbon(+this.carbonData.id, {
			// 	verified: 3
			// });

			// this.dataToSend.verified = 3;

			this.loadingForMarkReview = false;

			this.createToastForMobile(this.FormMSG(87, 'Success'), this.FormMSG(88, 'Update successfully!'));

			this.$emit('co-two-clap:reviewed');
		},
		handleCoTwoWasteChange(payload) {
			this.resetDataToSend();

			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		async submit(sendEvent = true) {
			this.dataToSend.parentId = this.parentId;
			this.dataToSend.entityType = this.entityType;
			this.dataToSend.projectId = +store.state.myProject.id;
			this.dataToSend.amount = +this.dataToSend.amount;

			if (sendEvent === false) {
				this.dataToSend.verified = true;
			}

			if (this.forCreate === true) {
				// const result = await newCarbon(this.dataToSend);

				if (sendEvent === true) {
					this.$emit('co-two-clap:end-submit');
				}
			} else if (this.forCreate === false) {
				// const result = await updCarbon(+this.carbonData.id, this.dataToSend);

				if (sendEvent === true) {
					this.$emit('co-two-clap:end-submit');
				}
			}
		},
		resetDataToSend() {
			const coTwoCategory = this.dataToSend.coTwoCategory;
			const boolValue = this.dataToSend.boolValue;
			this.dataToSend = {
				...INIT_DATA_CARBON,
				boolValue,
				coTwoCategory: coTwoCategory
			};
		},
		handleCoTwoEnergyChange(payload) {
			this.resetDataToSend();

			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleCoTwoTransportChange(payload) {
			this.dataToSend.type = payload.type;
			this.dataToSend.subType = payload.subType;

			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleCoTwoAccomodationChange(payload) {
			this.dataToSend.type = payload.type;
			this.dataToSend.numberBedroom = payload.numberBedroom;
			this.dataToSend.numberOfNight = payload.numberOfNight;

			this.emitChange();
		},
		handleCoTwoMealChange(payload) {
			this.resetDataToSend();

			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleCoTwoFuelChange(payload) {
			this.resetDataToSend();

			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleCoTwoTransportCarLodgesChange(payload) {
			this.resetDataToSend();

			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleCoTwoGeneratorEnergyChange(payload) {
			this.resetDataToSend();

			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleCoTwoTransportationShootingChange(payload) {
			this.resetDataToSend();

			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleCoTwoTechnicalProductionChange(payload) {
			this.resetDataToSend();

			this.dataToSend.type = payload.type;

			this.emitChange();
		},
		handleCoTwoSetCreationChange(payload) {
			this.resetDataToSend();

			this.dataToSend.type = payload.type;

			this.emitChange();
		},
		handleCoTwoHmcChange(payload) {
			this.resetDataToSend();
			this.dataToSend.type = payload.type;
			this.dataToSend.boolValue = payload.boolValue;
			//
			this.emitChange();
		},
		handleChangeCoTwoCategory(payload) {
			if (payload === 2) {
				this.dataToSend.type = null;
				this.carbonData.type = null;
			} else if (payload === 3) {
				this.dataToSend.type = 1;
				this.carbonData.type = 1;
				this.dataToSend.locationId = null;
				this.carbonData.locationId = null;
				this.dataToSend.subType = 1;
				this.carbonData.subType = 1;
			} else if (payload === 4) {
				this.dataToSend.reason = 0;
				this.carbonData.reason = 0;
				this.dataToSend.numberOfVehicule = 1;
				this.carbonData.numberOfVehicule = 1;
				this.dataToSend.type = 0;
				this.carbonData.type = 0;
			} else if (payload === 8) {
				this.dataToSend.type = 2;
				this.carbonData.type = 2;
			} else if (payload === 10) {
				this.dataToSend.type = 1;
				this.carbonData.number = 0;
			} else if (payload === 12) {
				this.dataToSend.type = 1;
				this.carbonData.type = 1;
			} else if (payload === 14) {
				this.dataToSend.type = 1;
				this.carbonData.type = 1;
			}

			this.emitChange();
		},
		constructCarbonDataManually(val) {
			this.carbonData = {
				id: 0,
				parentId: 0,
				entityType: 0,
				entityId: 0,
				strDate: new Date().toISOString(),
				endDate: new Date().toISOString(),
				coTwoCategory: val.constructor,
				type: val.type,
				subType: val.subType,
				paidByProduction: false,
				verified: false,
				boolValue: val.boolValue,
				km: val.km,
				liter: val.liter,
				mcube: val.mcube,
				kwh: val.kwh,
				msquare: val.msquare,
				description: 0,
				number: val.number,
				amount: 0,
				mealRed: val.mealRed,
				mealWhite: val.mealWhite,
				mealVege: val.mealVege,
				mealNotSpecified: val.mealNotSpecified,
				accommodation: {
					nbBedroom: 0,
					nbNight: 0,
					type: val.type
				},
				vehiculeId: val.vehiculeId,
				additionalSubType: val.additionalSubType,
				reason: val.reason,
				nbVehicle: val.number,
				numberOfVehicule: val.number,
				materialWeight: 0,
				fromAddressId: 0,
				toAddressId: 0
			};
		},
		emitChange() {
			if (this.dataToSend.coTwoCategory === 1 || this.dataToSend.coTwoCategory === 15) {
				this.dataToSend.type = this.energySavingSelected;
			} else if (this.dataToSend.coTwoCategory === 5) {
				// this.dataToSend.type = this.wasteTypeSelected;
				// this.dataToSend.boolvalue = this.wasteSorted;
			} else if (this.dataToSend.coTwoCategory === 4) {
				this.dataToSend.boolValue = this.transportDistanceKnown;
			}

			if (this.dataToSend.verified !== 3) {
				if (this.dataToSend.coTwoCategory === 0) {
					this.dataToSend.verified = 0;
				} else {
					this.dataToSend.verified = 2;
				}
			}

			this.$emit('co-two-clap:change', {
				carbonData: this.dataToSend
			});
		}
	}
};
</script>

<style scoped></style>
