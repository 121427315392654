var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "fieldset",
        {
          staticClass: "my-0 py-0 pb-0",
          class: `${
            _vm.$screen.width <= 576
              ? "card-inside bg-color-white border-ligth-starlight"
              : ""
          }`,
        },
        [
          _vm.$screen.width <= 576
            ? _c(
                "legend",
                {
                  staticClass: "text-color-rhapsody-in-blue-2",
                  class: `${_vm.$screen.width <= 576 ? "card-inside" : ""}`,
                },
                [
                  _vm._v(
                    "\n\t\t\t" + _vm._s(_vm.FormMSG(687, "Gas")) + "\n\t\t"
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { class: { "pt-1 pb-1": _vm.$screen.width <= 576 } },
            [
              _c(
                "b-row",
                [
                  _vm.$screen.width > 576
                    ? _c(
                        "b-col",
                        { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
                        [
                          _c("b-form-input", {
                            staticClass: "bg-color-grams-hair",
                            attrs: {
                              value: _vm.FormMSG(687, "Gas"),
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c("supplier-energy-consumption", {
                        attrs: {
                          label: _vm.FormMSG(633, "Supplier"),
                          "dialog-title": (253, "Gas supplier"),
                          "hide-label": _vm.$screen.width > 576,
                          "supplier-type": "gas",
                          "label-add-supplier": _vm.FormMSG(
                            198,
                            "New gas supplier"
                          ),
                          "supplier-id": _vm.payload.gasSupplier,
                        },
                        on: {
                          "supplier-energy:change":
                            _vm.handleSupplierSelectorChange,
                          "supplier-energy:unselect":
                            _vm.handleSupplierUnselect,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label:
                              _vm.$screen.width <= 576
                                ? _vm.FormMSG(58, "Meter start")
                                : "",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                staticClass: "br-none",
                                attrs: {
                                  id: "location_gas_start",
                                  placeholder: "0",
                                },
                                model: {
                                  value: _vm.payload.gasMeterStart,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payload, "gasMeterStart", $$v)
                                  },
                                  expression: "payload.gasMeterStart",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "bg-color-white input-group-text-bg-white",
                                    },
                                    [
                                      _c("div", { staticClass: "d-flex" }, [
                                        _vm.has(
                                          _vm.payload.gasMeterStartPict
                                        ) &&
                                        _vm.payload.gasMeterStartPict !== ""
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-blue-rinse",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showPicture([
                                                      _vm.payload
                                                        .gasMeterStartPict,
                                                    ])
                                                  },
                                                },
                                              },
                                              [
                                                _c(_vm.getLucideIcon("Image"), {
                                                  tag: "component",
                                                  attrs: { size: 20 },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.$isPwa()
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-rhapsody-in-blue",
                                                on: {
                                                  click:
                                                    _vm.openGasPicModalStart,
                                                },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    "Paperclip"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: { size: 20 },
                                                  }
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.$isPwa()
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-rhapsody-in-blue",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.startMobilePwaCapture(
                                                      "handleGasPicStart"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    "Paperclip"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: { size: 20 },
                                                  }
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label:
                              _vm.$screen.width <= 576
                                ? _vm.FormMSG(59, "Meter end")
                                : "",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                staticClass: "br-none",
                                attrs: { placeholder: "0" },
                                model: {
                                  value: _vm.payload.gasMeterEnd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payload, "gasMeterEnd", $$v)
                                  },
                                  expression: "payload.gasMeterEnd",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "bg-color-white input-group-text-bg-white",
                                    },
                                    [
                                      _c("div", { staticClass: "d-flex" }, [
                                        _vm.has(_vm.payload.gasMeterEndPict) &&
                                        _vm.payload.gasMeterEndPict !== ""
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-blue-rinse",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showPicture([
                                                      _vm.payload
                                                        .gasMeterEndPict,
                                                    ])
                                                  },
                                                },
                                              },
                                              [
                                                _c(_vm.getLucideIcon("Image"), {
                                                  tag: "component",
                                                  attrs: { size: 20 },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.$isPwa()
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-rhapsody-in-blue",
                                                on: {
                                                  click: _vm.openGasPicModalEnd,
                                                },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    "Paperclip"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: { size: 20 },
                                                  }
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.$isPwa()
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-rhapsody-in-blue",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.startMobilePwaCapture(
                                                      "handleGasPicEnd"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    "Paperclip"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: { size: 20 },
                                                  }
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                model: {
                                  value: _vm.payload.consumptionGas,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payload, "consumptionGas", $$v)
                                  },
                                  expression: "payload.consumptionGas",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "bg-color-north-wind text-color-white",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(260, "m3")) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("Capture", {
        attrs: {
          "custom-ref": _vm.rendRef("pic_elec_system_start"),
          "auto-crop-capture": "",
          "only-one-crop-mode": "",
          "parent-id": _vm.parentId,
          "parent-type": "location",
          "parent-sub-type": "location_gas_start",
        },
        on: { change: _vm.handleGasPicStart },
        model: {
          value: _vm.isGasPicStartModalOpen,
          callback: function ($$v) {
            _vm.isGasPicStartModalOpen = $$v
          },
          expression: "isGasPicStartModalOpen",
        },
      }),
      _c("Capture", {
        attrs: {
          "custom-ref": _vm.rendRef("pic_elec_system_end"),
          "auto-crop-capture": "",
          "only-one-crop-mode": "",
          "parent-id": _vm.parentId,
          "parent-type": "location",
          "parent-sub-type": "location_gas_end",
        },
        on: { change: _vm.handleGasPicEnd },
        model: {
          value: _vm.isGasPicEndModalOpen,
          callback: function ($$v) {
            _vm.isGasPicEndModalOpen = $$v
          },
          expression: "isGasPicEndModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }