<template>
	<div>
		<b-row>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(261, 'Reason')" :state="!$v.dataToSend.type.$error">
					<b-form-select
						v-model="$v.dataToSend.type.$model"
						:options="reasonTypes"
						@change="handleChangeReason"
						:state="!$v.dataToSend.type.$error"
					/>
					<div v-if="$v.dataToSend.type.$error" class="invalid-feedback">
						{{ FormMSG(296, 'Reason is required') }}
					</div>
				</b-form-group>
			</b-col>
			<b-col v-if="dataToSend.type === 1 || dataToSend.type === 2" sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="labelLocation">
					<v-select
						v-model="$v.dataToSend.locationId.$model"
						:placeholder="FormMSG(263, 'Please select ...')"
						label="setName"
						:options="locations"
						:reduce="(option) => option.id"
						:class="{ 'is-invalid': $v.dataToSend.locationId.$error }"
						@option:selected="handleOptionSelectedLocation"
					/>
					<div v-if="$v.dataToSend.locationId.$error" class="invalid-feedback">
						{{ FormMSG(295, 'Location is required') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row v-if="dataToSend.type > 0">
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(267, 'Energy type')" :state="!$v.dataToSend.subType.$error">
					<b-form-select
						v-model="$v.dataToSend.subType.$model"
						:options="energyTypes"
						:state="!$v.dataToSend.subType.$error"
						@change="handleChangeSubType"
					/>
					<div v-if="$v.dataToSend.subType.$error" class="invalid-feedback">
						{{ FormMSG(300, 'Energy type is required') }}
					</div>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(275, 'Consumption')" :state="!$v.consumption.$error">
					<b-input-group>
						<b-form-input v-model="$v.consumption.$model" @change="handleChangeConsumption" :state="!$v.consumption.$error" />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ unitConsumption }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
					<div v-if="$v.consumption.$error" class="invalid-feedback">
						{{ FormMSG(300, 'Energy type is required') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { getLocations } from '@/cruds/locations.crud';
import { getProjectLocationListForPremises } from '@/cruds/premises.crud';
import languageMessages from '@/mixins/languageMessages';
import { validationMixin } from 'vuelidate';
import { required, minValue, decimal } from 'vuelidate/lib/validators';
import { REASON_ENERGY_TYPE_MENU, ENERGY_TYPE_FOR_LOCATION_PROMISE, ENERGY_TYPE_MENU, CAR_LODGE_ENERGY_TYPE_MENU } from '@/shared/constants';

export default {
	name: 'CoTwoEnergyForm',

	props: {
		coTwoData: { type: Object, default: () => ({}), required: false }
	},

	mixins: [languageMessages, validationMixin],

	data() {
		return {
			dataToSend: {
				type: 0,
				locationId: null,
				subType: 0,
				liter: 0,
				kwh: 0,
				mcube: 0,
				waterVolumeUsed: 0,
				kg: 0
			},

			consumption: 0,
			locations: [],
			forInit: true
		};
	},

	validations: {
		dataToSend: {
			type: { minValue: minValue(1) },
			locationId: { required },
			subType: { minValue: minValue(1) }
		},
		consumption: { minValue: minValue(1) }
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				this.dataToSend.type = newVal.type;
				this.dataToSend.locationId = newVal.locationId === 0 || newVal.locationId === null ? null : '' + newVal.locationId;
				this.dataToSend.subType = newVal.subType;

				this.addValueToConsumption(newVal);

				this.$nextTick(async () => {
					if (this.forInit === true) {
						await this.handleChangeReason(newVal.type, true);
					}
				});
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		unitConsumption() {
			if (this.dataToSend.type === 1 || this.dataToSend.type === 2) {
				if (this.dataToSend.subType === 1) {
					return this.FormMSG(281, 'kwh');
				} else if (this.dataToSend.subType === 2) {
					return this.FormMSG(282, 'm3');
				} else if (this.dataToSend.subType === 3) {
					return this.FormMSG(282, 'm3');
				} else if (this.dataToSend.subType === 4) {
					return this.FormMSG(283, 'L');
				} else if (this.dataToSend.subType === 5) {
					return this.FormMSG(282, 'm3');
				} else if (this.dataToSend.subType === 6) {
					return this.FormMSG(298, 'kg');
				}
			} else if (this.dataToSend.type === 3) {
				if (this.dataToSend.subType === 1) {
					return this.FormMSG(283, 'L');
				} else if (this.dataToSend.subType === 2) {
					return this.FormMSG(283, 'L');
				} else if (this.dataToSend.subType === 3) {
					return this.FormMSG(282, 'm3');
				}
			}
		},
		energyTypes() {
			if (this.dataToSend.type === 1) {
				return this.FormMenu(ENERGY_TYPE_MENU);
			} else if (this.dataToSend.type === 2) {
				return this.FormMenu(ENERGY_TYPE_MENU);
			} else if (this.dataToSend.type === 3) {
				return this.FormMenu(CAR_LODGE_ENERGY_TYPE_MENU);
			}

			return [];
		},
		reasonTypes() {
			return [{ value: 0, text: this.FormMSG(263, 'Please select ...') }, ...this.FormMenu(REASON_ENERGY_TYPE_MENU)];
		},
		labelLocation() {
			if (this.dataToSend.type === 1) {
				return this.FormMSG(262, 'Location selection');
			} else if (this.dataToSend.type === 2) {
				return this.FormMSG(265, 'Premise selection');
			}
			return '';
		}
	},

	methods: {
		isInvalid() {
			this.$v.$touch();

			return this.$v.$invalid;
		},
		addValueToConsumption(val) {
			if (this.dataToSend.type === 1 || this.dataToSend.type === 2) {
				if (this.dataToSend.subType === 1) {
					this.consumption = val.kwh;
				} else if (this.dataToSend.subType === 2) {
					this.consumption = val.mcube;
				} else if (this.dataToSend.subType === 4) {
					this.consumption = val.liter;
				} else if (this.dataToSend.subType === 5) {
					this.consumption = val.mcube;
				} else if (this.dataToSend.subType === 3) {
					this.consumption = val.waterVolumeUsed;
				}
			} else if (this.dataToSend.type === 3) {
				if (this.dataToSend.subType === 1) {
					this.consumption = val.liter;
				} else if (this.dataToSend.subType === 2) {
					this.consumption = val.liter;
				} else if (this.dataToSend.subType === 3) {
					this.consumption = val.mcube;
				}
			}
		},
		handleChangeSubType() {
			this.emitChange();
		},
		handleOptionSelectedLocation() {
			this.emitChange();
		},
		async handleChangeConsumption() {
			this.dataToSend.kwh = 0;
			this.dataToSend.mcube = 0;
			this.dataToSend.liter = 0;
			this.dataToSend.waterVolumeUsed = 0;

			if (this.dataToSend.type === 1 || this.dataToSend.type === 2) {
				if (this.dataToSend.subType === 1) {
					this.dataToSend.kwh = +this.consumption;
				} else if (this.dataToSend.subType === 2) {
					this.dataToSend.mcube = +this.consumption;
				} else if (this.dataToSend.subType === 3) {
					this.dataToSend.waterVolumeUsed = +this.consumption;
				} else if (this.dataToSend.subType === 4) {
					this.dataToSend.liter = +this.consumption;
				} else if (this.dataToSend.subType === 5) {
					this.dataToSend.mcube = +this.consumption;
				} else if (this.dataToSend.subType === 6) {
					this.dataToSend.kg = +this.consumption;
				}
			} else if (this.dataToSend.type === 3) {
				if (this.dataToSend.subType === 1) {
					this.dataToSend.liter = +this.consumption;
				} else if (this.dataToSend.subType === 2) {
					this.dataToSend.liter = +this.consumption;
				} else if (this.dataToSend.subType === 3) {
					this.dataToSend.mcube = +this.consumption;
				}
			}

			this.emitChange();
		},
		async handleChangeReason(payload, forWatch = false) {
			this.locations = [];

			if (!forWatch) {
				this.dataToSend.locationId = null;
				this.dataToSend.subType = 1;
			}

			if (payload === 1) {
				this.locations = await getLocations({});
			} else if (payload === 2) {
				this.locations = await getProjectLocationListForPremises();
			}

			if (!forWatch) {
				this.emitChange();
			}
		},
		emitChange() {
			this.forInit = false;
			this.dataToSend.locationId = +this.dataToSend.locationId;
			this.$emit('co-two-energy:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
